import React from 'react';
import Tools from "../../../components/Tools/Tools"
import ExpenseMprReport from '../../../components/Multi/MRP/ExpenseMprReport';

const ExpenseMprReportPage = () => {
  return (
    <div>
      <Tools/>
      <ExpenseMprReport />
    </div>
  )
}

export default ExpenseMprReportPage
