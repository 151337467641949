import React, { useState, useEffect, useRef } from 'react'
import { Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { BiTrash, BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { ADD_CHALLAN_FAIL, ADD_CHALLAN_START } from "../../RTK/Slices/ChallanSlice";
import {
    get_financial_year,
    add_budget_surrender,
    add_budget_reappropriate_subhead,
    get_schemes,
    get_single_budget_reappropriate, getActivity, getSubActivity
} from '../../helper/Api';
import { toast } from 'react-toastify';
import { DeleteForever } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

export default function AddBudgetSurrenderComponent() {
    const dispatch = useDispatch();
        const { authData } = useSelector((state) => state.authData);
        const { id } = useParams();
        const [schemes, set_schemes] = useState([]);
        const [fy_list, set_fy_list] = useState([]);
        const [subheadsList, set_subheadsList] = useState([]);
        const [toSubheadsList, setToSubheadsList] = useState([]);
        const [fromSubheadsList, setFromSubheadsList] = useState([]);
        const [activity, setActivity] = useState([]);
        const [subActivity, setSubActivity] = useState([]);
        const totalAmount = useRef(0);
        const total1Amount = useRef(0);
        const totalFromAmount = useRef(0);
        const totalToAmount = useRef(0);
        const activeAddToSubhead = useRef(true);
        const navigate = useNavigate();
        const [expandedIndex, setExpandedIndex] = useState(null);
        const [formData, setFormData] = useState({
            id: '',
            budget_surrender_order_no: '',
            budget_surrender_order_date: '',
            budget_surrender_order_value: '',
            attachment: [],
            department_Id: "",
            department_name: "",
            financial_id: '',
            financial_year: '',
            activity: [],
            subhead_id: '',
            created_by: authData && authData.status ? authData.user.id : null
        });
        const [showConfirm, setShowConfirm] = useState(false);
        const [schemeLoading, setSchemeLoading] = useState(false);
        const [fromSchemeDetails, setFromSchemeDetails] = useState({
            scheme_id: '',
            scheme_name: '',
            department_name: '',
            grant_code: '',
            scheme_type: '',
            financial_id: '',
            financial_year: '',
            monitoring_level: '',
            is_activity_active: '',
        });
        const [fromSubheadData, setFromSubheadData] = useState({
            from_scheme_id: '',
            from_subhead_id: '',
            from_financial_id: '',
            from_reappropriation_amount: '',
            scheme_name: '',
            financial_year: '',
            subhead_name: '',
            subhead_balance: '',
            activity: [{
                activity_id: '',
                subactivity_id: '',
                provisional_budget: 0,
                budget: 0,
            }],
            activity_budget: [],
        })
        // handle formal formdata
        const handleChallanFormData = (e) => {
            const name = e.target.name;
            const value = e.target.value;
            setFormData({
                ...formData,
                [name]: value
            });
            if (name == 'from_scheme_id') {
                if (value) {
                    const filter = schemes.find((scheme) => scheme.id == value);
                    if (filter) {
                        const schemeList = schemes.filter(f => f.type == filter.type);
                        setFromSchemeDetails(prevState => {
                            return {
                                ...prevState, scheme_name: filter.name,
                                department_name: filter.department,
                                grant_code: filter.grant_code,
                                scheme_type: filter.type,
                                is_activity_active: filter.is_activity_active,
                                monitoring_level: filter.monitoring_level,
                            }
                        })
                        set_subheadsList(filter.sub_head_list);
                        setFromSchemeDetails(prevState => {
                            return {
                                ...prevState, to_scheme_name: '',
                                to_department_name: '',
                                to_grant_code: '',
                                to_scheme_type: '',
                                to_subhead_name: '',
                                to_subhead_balance: '',
                            }
                        })
                        // clean to form data ends
                    } else {
                        set_subheadsList([]);
                    }
                } else {
                    set_subheadsList([]);
                }
            }
    
            if (name == 'from_subhead_id') {
                if (value) {
                    const subhead = subheadsList.find(e => e.id == value);
                    if (subhead) {
                        setFromSchemeDetails(prevState => {
                            return {
                                ...prevState,
                                subhead_name: subhead.name,
                                subhead_balance: subhead.balance,
                                subhead_budget: Number(subhead.budget),
                                subhead_provisional_budget: Number(subhead.provisional_budget),
                                activity_budget: subhead.activity_budget,
                            }
                        })
                        setFromSubheadData(prevState => {
                            return {
                                ...prevState,
                                subhead_name: subhead.name,
                                subhead_balance: subhead.balance,
                                subhead_budget: Number(subhead.budget),
                                subhead_provisional_budget: Number(subhead.provisional_budget),
                                activity_budget: subhead.activity_budget,
                            }
                        })
                    }
                }
            }
        }
        // handle from scheme details
        const handleSchemeChange = (event) => {
            const selectedSchemeId = event.target.value;
            if (selectedSchemeId && fromSchemeDetails.scheme_id) {
                setShowConfirm(true);
                return
            }
            // Find the scheme from the schemes list based on the selected scheme_id
            const selectedScheme = schemes.find(scheme => scheme.id === selectedSchemeId);
    
            if (selectedScheme) {
                // Set the state with the details of the selected scheme
                setFromSchemeDetails({
                    ...fromSchemeDetails,
                    scheme_id: selectedScheme.id,
                    scheme_name: selectedScheme.name,
                    department_name: selectedScheme.department,
                    grant_code: selectedScheme.grant_code,
                    scheme_type: selectedScheme.type,
                    monitoring_level: selectedScheme.monitoring_level,
                    is_activity_active: selectedScheme.is_activity_active,
                });
                set_subheadsList(selectedScheme.sub_head_list);
            }
            else {
                set_subheadsList([])
            }
            console.log("scheme detailn set==>>", selectedScheme, fromSchemeDetails);
        };
        //change financial year 
        const handleFinancialYearChange = (event) => {
            const selectedYearId = event.target.value;
            const selectedYearName = event.target.options[event.target.selectedIndex].text;
            setFromSchemeDetails({
                ...fromSchemeDetails,
                financial_id: selectedYearId,
                financial_year: selectedYearName,
            });
            setFormData({
                ...formData,
                financial_id: selectedYearId,
                financial_year: selectedYearName,
            });
            fetch_list(formData.department_Id, selectedYearId);
        };
        //change subhead  
        const handleSubheadChange = (event) => {
            const { value, name } = event.target;
            const selectedSubheadId = value;
            const alreadySelected = fromSubheadsList.find(subhead => subhead.from_subhead_id === selectedSubheadId);
            if (alreadySelected) {
                return alert('Subhead already selected');
            }
            const selectedSubhead = subheadsList.find(subhead => subhead.id === selectedSubheadId); // Find the subhead in the list
            if (selectedSubhead) {
                setFromSubheadData((prevState) => ({
                    ...prevState,
                    from_subhead_id: selectedSubhead.id,
                    subhead_name: selectedSubhead.name,
                    subhead_balance: selectedSubhead.balance,
                    activity_budget: selectedSubhead.activity_budget || [],
                }));
            }
            if (selectedSubheadId && fromSubheadData.from_subhead_id) {
                setShowConfirm(true);
            }
        };
        //change subhead  
        // const handleToSubheadChange = (event) => {
        //     const { value, name } = event.target;
        //     const selectedSubheadId = value;
        //     const alreadySelected1 = toSubheadsList.find(subhead => subhead.from_subhead_id === selectedSubheadId);
        //     const alreadySelected2 = fromSubheadsList.find(subhead => subhead.from_subhead_id === selectedSubheadId);
        //     if (alreadySelected1 || alreadySelected2 || selectedSubheadId == fromSubheadData.from_subhead_id) {
        //         return alert('Subhead already selected');
        //     }
        //     const selectedSubhead = subheadsList.find(subhead => subhead.id === selectedSubheadId); // Find the subhead in the list
        //     if (selectedSubhead) {
        //         setToSubheadData((prevState) => ({
        //             ...prevState,
        //             to_subhead_id: selectedSubhead.id,
        //             subhead_name: selectedSubhead.name,
        //             subhead_balance: selectedSubhead.balance,
        //             activity_budget: selectedSubhead.activity_budget || [],
        //         }));
        //     }
        // };
        // from activity change
        const handleFromActivityChange = (activityIndex, e, activityId) => {
            const { name, value } = e.target;
            const activities = fromSubheadData.activity;  // Access the activity array from `fromSubheadData`
    
            // Check if the selected subactivity is already selected for the same activity_id
            if (activityId) {
                const activityValid = activities.find(
                    (item) => item.subactivity_id === value && item.activity_id === activityId
                );
                if (activityValid) {
                    return alert('Sub-activity already selected');
                }
            }
    
            // Handle budget change logic
            if (name === 'budget') {
                let totalBudget = 0;
                const updatedActivities = activities.map((item, index) => {
                    const updatedBudget = index === activityIndex ? Number(value) : Number(item.budget);
                    totalBudget += updatedBudget;
                    return {
                        ...item,
                        budget: updatedBudget,
                    };
                });
    
                let activityData = activities[activityIndex];
                if (activityData) {
                    // If monitoring level is 2, validate against subhead_balance
                    if (fromSchemeDetails.monitoring_level === "2") {
                        if (Number(fromSubheadData.subhead_balance) < Number(totalBudget)) {
                            return alert('Total available amount exceeds the amount.');
                        }
                    } else {
                        // Validate the budget with activity_budget if available
                        if (fromSubheadData?.activity_budget) {
                            const subheadActivityData = fromSubheadData?.activity_budget.find(
                                (item) =>
                                    item.subhead_activity_id === activityData.activity_id &&
                                    item.subhead_subactivity_id === activityData.subactivity_id
                            );
                            if (subheadActivityData && Number(subheadActivityData.budget) < Number(value)) {
                                return alert('Total available amount exceeds the amount.');
                            }
                        }
                    }
                }
                totalFromAmount.current = totalBudget;
                // // New: Check if total budget exceeds `totalFromAmount.current`
                // const totalAvailableAmount = Number(totalFromAmount?.current || 0);
                // if (totalBudget > totalAvailableAmount) {
                //     return alert('Total budget exceeds the available amount.');
                // }
            }
    
            setFromSubheadData((prevState) => {
                const updatedActivities = prevState.activity.map((activity, aIdx) => {
                    if (aIdx === activityIndex) {
                        const updatedActivity = {
                            ...activity,
                            [name]: value,  // Update the field that triggered the change
                        };
                        // Reset subactivity_id if activity_id changes
                        if (name === 'activity_id') {
                            updatedActivity.subactivity_id = '';
                        }
                        return updatedActivity;
                    }
                    return activity;
                });
    
                return {
                    ...prevState,
                    activity: updatedActivities,  // Update the activity array
                };
            });
        };
        
        const handleFromSubheadSave = () => {
            let totalFrom=totalFromAmount.current;
            const isProvisionalBudgetZero = fromSubheadData.activity.some(activity => activity.budget === 0);
            if (
                !fromSubheadData.from_subhead_id ||
                !fromSubheadData.subhead_name ||
                !totalFromAmount.current
            ) {
                toast.error('Please fill all required fields', {
                    position: toast.POSITION.TOP_CENTER
                });
                return;
            }
            if (isProvisionalBudgetZero) {
                toast.error('Please fill all required fields (provisional budget cannot be zero)', {
                    position: toast.POSITION.TOP_CENTER
                });
                return; // Prevent further execution
            }
            console.log('fromsubheaddaaas', fromSubheadData);
            setFromSubheadsList(prevState => [
                ...prevState,
                {
                    ...fromSubheadData,
                    from_reappropriation_amount: totalFrom
                }
            ]);
            totalAmount.current += Number(totalFrom);
            setFromSubheadData({
                from_scheme_id: '',
                from_subhead_id: '',
                from_financial_id: '',
                from_reappropriation_amount: '',
                scheme_name: '',
                financial_year: '',
                subhead_name: '',
                subhead_balance: '',
                activity: [{
                    activity_id: '',
                    subactivity_id: '',
                    provisional_budget: 0,
                    budget: 0,
                }],
                activity_budget: [],
            });
            totalFromAmount.current = "";
        };
        
        const toggleNestedTable = (index) => {
            setExpandedIndex(prevIndex => (prevIndex === index ? null : index)); // If the same row is clicked, collapse it
        };


        const fetch_list = async (financial_id) => {
            setSchemeLoading(true);
            const fy = await get_financial_year();
            const schemes_list = await get_schemes({ department_id: authData.user.department_id, fy_id: financial_id });
            if (schemes_list.data.status) {
                set_schemes(schemes_list.data.schemes);
                setSchemeLoading(false);
            } else {
                set_schemes([]);
            }
            if (fy.data.status) {
                set_fy_list(fy.data.list)
            }
    
            if (id) {
                const budgetData = await get_single_budget_reappropriate(id);
            }
    
        }
        const handleAttachmentChange = (event) => {
            const files = event.target.files;
            setFormData({
                ...formData,
                attachment: files
            });
        };
    
        const previewHandler = (e) => {
            e.preventDefault();
        };
    
        const handleSubmit = async (e) => {
            if (!id) {
                if (e && typeof e.preventDefault === 'function') {
                    e.preventDefault();
                }
            }
            try {
                console.log('formData', formData);
                if (formData.budget_surrender_order_no &&
                    formData.budget_surrender_order_date && formData.budget_surrender_order_value > 0) {
                    const post_data = new FormData();
                    for (let i = 0; i < formData.attachment.length; i++) {
                        post_data.append('attachment[]', formData.attachment[i]);
                    }
                    post_data.append('scheme_id', fromSchemeDetails.scheme_id);
                    post_data.append('budget_surrender_order_no', formData.budget_surrender_order_no);
                    post_data.append('budget_surrender_order_date', formData.budget_surrender_order_date);
                    post_data.append('budget_surrender_order_value', formData.budget_surrender_order_value);
                    post_data.append('subheads', JSON.stringify(fromSubheadsList));
                    post_data.append('financial_year', formData.financial_id);
                    post_data.append('created_by', formData.created_by);
                    post_data.append('activity', JSON.stringify(fromSubheadData.activity));
                    dispatch(ADD_CHALLAN_START());
                    // console.log('formData ==>', post_data); return;
                    const response = await add_budget_surrender(post_data);
                    let addDepositoryResponseData = response.data;
                    if (addDepositoryResponseData.status) {
                        // navigate('/budget-reappropriate');
                    } else {
                        toast.error(addDepositoryResponseData.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        dispatch(ADD_CHALLAN_FAIL(addDepositoryResponseData.message));
                    }
                } else {
                    toast.error('Please fill required fields.', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            } catch (error) {
                dispatch(ADD_CHALLAN_FAIL('error getting while updating challan'));
    
            }
        }
    
        useEffect(() => {
            fetch_list();
            fetchActivity();
            fetchSubActivity();
        }, []);

        const removeSubhead = (scheme_id, subhead_id, listType = 'to') => {
            const targetList = listType === 'to' ? toSubheadsList : fromSubheadsList;
            const updatedSubHeads = targetList.filter(e => e.to_scheme_id !== scheme_id || e.to_subhead_id !== subhead_id);
            totalAmount.current = 0;
            updatedSubHeads.forEach(e => {
                totalAmount.current += Number(e.to_reappropriation_amount || e.from_reappropriation_amount);
            });
            if (listType === 'to') {
                total1Amount.current = 0;
                updatedSubHeads.forEach(e => {
                    totalAmount.current += Number(e.to_reappropriation_amount);
                });
                setToSubheadsList(updatedSubHeads);
            } else {
                totalAmount.current = 0;
                updatedSubHeads.forEach(e => {
                    totalAmount.current += Number(e.from_reappropriation_amount);
                });
                setFromSubheadsList(updatedSubHeads);
            }
        };

        const fetchActivity = async () => {
            try {
                const resp = await getActivity();
                if (resp.data.status) {
                    setActivity(resp.data.result);
                }
            } catch (error) {
                setActivity([]);
            }
        }
        const fetchSubActivity = async () => {
            try {
                const resp = await getSubActivity();
                if (resp.data.status) {
                    setSubActivity(resp.data.result);
                }
            } catch (error) {
                setSubActivity([]);
            }
        }
        const findSubActivitiesById = (id) => {
            let listData = subActivity;
            return listData.filter(subActivity => subActivity.activity_id === id);
        };
        const handleSubheadBudgetFormData = (activityIndex, e) => {
            const { name, value } = e.target;
            let totalProvisionalBudget = 0;
            let totalBudget = 0;
            fromSchemeDetails.activity.forEach((item, activityI) => {
                if (activityI !== activityIndex) {
                    totalProvisionalBudget += parseFloat(item.provisional_budget || 0);
                    totalBudget += parseFloat(item.budget || 0);
                }
            });
            if (name === 'provisional_budget') {
                totalProvisionalBudget = Number(totalProvisionalBudget) + Number(value);
                if (totalProvisionalBudget > Number(fromSchemeDetails.subhead_provisional_budget)) {
                    fromSchemeDetails.activity[activityIndex]['provisional_budget'] = 0;
                    return alert('Total provisional budget exceeds the allowed amount=' + fromSchemeDetails.subhead_provisional_budget);
                }
            }
            if (name === 'budget') {
                totalBudget = totalBudget + Number(value);
                if (totalBudget > Number(fromSchemeDetails.subhead_budget)) {
                    fromSchemeDetails.activity[activityIndex]['budget'] = 0;
                    return alert('Total budget exceeds the allowed amount=' + fromSchemeDetails.subhead_budget);
                }
            }
            setFromSchemeDetails(prevDetails => {
                const updatedActivities = prevDetails.activity.map((activity, aIdx) => {
                    if (aIdx === activityIndex) {
                        const updatedActivity = {
                            ...activity,
                            [name]: value
                        };
                        if (name === 'activity_id') {
                            updatedActivity.subactivity_id = '';
                        }
    
                        return updatedActivity;
                    }
                    return activity;
                });
                return {
                    ...prevDetails,
                    activity: updatedActivities
                };
            });
            console.log(fromSchemeDetails.activity);
        };
        
        const addNewActivity = (type) => {
            const newActivity = { activity_id: '', subactivity_id: '', provisional_budget: 0, budget: 0 };
            // if (type == 'from') {
                setFromSubheadData(prevDetails => {
                    return {
                        ...prevDetails,
                        activity: [...prevDetails.activity, newActivity]
                    };
                });
            // }
            // else {
            //     setToSubheadData(prevDetails => {
            //         return {
            //             ...prevDetails,
            //             activity: [...prevDetails.activity, newActivity]
            //         };
            //     });
            // }
        };
        const deleteNewActivity = (activityIndex, type) => {
            // if (type == 'from') {
                if (fromSubheadData.activity.length > 1) {
                    setFromSubheadData(prevDetails => {
                        const updatedActivities = [...prevDetails.activity];
                        updatedActivities.splice(activityIndex, 1);  // Remove activity at the specified index
                        return {
                            ...prevDetails,
                            activity: updatedActivities
                        };
                    });
                }
            // }
            // else {
            //     if (toSubheadData.activity.length > 1) {
            //         setToSubheadData(prevDetails => {
            //             const updatedActivities = [...prevDetails.activity];
            //             updatedActivities.splice(activityIndex, 1);  // Remove activity at the specified index
            //             return {
            //                 ...prevDetails,
            //                 activity: updatedActivities
            //             };
            //         });
            //     }
            // }
        };
        
        const handleConfirm = (value) => {
            setShowConfirm(value)
        }
        const confirmSubmit = () => {
            setShowConfirm(false)
            setFromSchemeDetails({
                scheme_id: '',
                scheme_name: '',
                department_name: '',
                grant_code: '',
                scheme_type: '',
                financial_id: '',
                financial_year: '',
                monitoring_level: '',
                is_activity_active: '',
            });
            setFromSubheadData({
                from_scheme_id: '',
                from_subhead_id: '',
                from_financial_id: '',
                from_reappropriation_amount: '',
                scheme_name: '',
                financial_year: '',
                subhead_name: '',
                subhead_balance: '',
                activity: [{
                    activity_id: '',
                    subactivity_id: '',
                    provisional_budget: 0,
                    budget: 0,
                }],
                activity_budget: []
            })
            setFromSubheadsList([])
            setToSubheadsList([])
            totalFromAmount.current = null;
            totalAmount.current = null;
        }
        return (
            <>
                <div>
                    <div className="p-3">
                        <div className="row">
                            {/* <Form onSubmit={previewHandler}> */}
                            <div className="col-md-12">
                                <div className="add_new_user">
                                    <h4> Add Budget Surrender</h4>
                                </div>
                                <div className="card p-3 mt-3">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="" className="col-form-label">Budget Surrender Order No. <span className="text-danger">*</span> :</label>
                                                <input type="text"
                                                    className="form-control"
                                                    name='budget_surrender_order_no'
                                                    required
                                                    value={formData.budget_surrender_order_no}
                                                    onChange={handleChallanFormData} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="" className="col-form-label">Budget Surrender Order Date <span className="text-danger">*</span> :</label>
                                                <input type="date"
                                                    className="form-control"
                                                    name='budget_surrender_order_date'
                                                    required
                                                    value={formData.budget_surrender_order_date}
                                                    onChange={handleChallanFormData} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="" className="col-form-label">Budget Surrender Order Value <span className="text-danger">*</span> :</label>
                                                <input type="text"
                                                    className="form-control"
                                                    name='budget_surrender_order_value'
                                                    required
                                                    value={formData.budget_surrender_order_value}
                                                    onChange={handleChallanFormData} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="input-group">
                                                <label className="input-group-btn my-0 mt-2 mr-2">
                                                    Upload Document  <span className="text-danger">*</span>
                                                </label>
                                                <label className='p-1'>
                                                    <input id="" type="file"
                                                        className='form-control'
                                                        onChange={handleAttachmentChange}
                                                        name='attachment'
                                                        accept=".pdf,.jpeg,.jpg,.png"
                                                        required
                                                        multiple />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <hr />
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label for="inputFinancial" className="col-form-label"> Financial Year <span className="text-danger">*</span> :</label>
                                                <select className="form-control " id="financial_year"
                                                    name='financial_id'
                                                    onChange={handleFinancialYearChange}
                                                    // disabled={schemeLoading}
                                                    required
                                                >
                                                    <option value="">---select year---</option>
                                                    {
                                                        fy_list.map((fy) => {
                                                            return (
                                                                <option value={fy.id}
                                                                    key={fy.id}>{fy.year}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="from_scheme_id" className="col-form-label">Scheme <span className="text-danger">*</span></label>
                                                <select className="form-control "
                                                    id="from_scheme_id" name='from_scheme_id'
                                                    required
                                                    value={fromSchemeDetails.scheme_id}
                                                    onChange={handleSchemeChange}
                                                    disabled={!formData.financial_id}
                                                >
                                                    <option>Select Scheme</option>
                                                    {
                                                        schemes.map((schemes) => {
                                                            return (
                                                                schemes.l2_status == 1 && schemes.l3_status == 1 &&
                                                                <option value={schemes.id}
                                                                    key={schemes.id}>{schemes.code}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="col-form-label">Scheme Name</label>
                                                <input type="text" className="form-control" value={fromSchemeDetails.scheme_name} readonly />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="col-form-label">Department Name</label>
                                                <input type="text" className="form-control" value={fromSchemeDetails.department_name} readonly />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="col-form-label">Grant Code</label>
                                                <input type="text" className="form-control" value={fromSchemeDetails.grant_code} readonly />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="col-form-label">Type of Scheme</label>
                                                <input type="text" className="form-control" value={fromSchemeDetails.scheme_type} readonly />
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="form-group">
                                                <label for="inputFinancial" className="col-form-label"> Subhead <span className="text-danger">*</span> :</label>
                                                <select className="form-control " id="financial_year"
                                                    name='from_subhead_id'
                                                    onChange={handleSubheadChange}
                                                    value={fromSubheadData.from_subhead_id}
                                                    required
                                                    disabled={!fromSchemeDetails.scheme_id}>
                                                    <option value="">---Select Subhead---</option>
                                                    {
                                                        subheadsList.map((subhead) => {
                                                            return (
                                                                <option
                                                                    value={subhead.id}
                                                                    key={subhead.id}>{subhead.code}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
    
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="col-form-label">Subhead Name</label>
                                                <input type="text" className="form-control" value={fromSubheadData.subhead_name} readonly />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="col-form-label">Subhead Balance</label>
                                                <input type="text" className="form-control" value={fromSubheadData.subhead_balance} readonly />
                                            </div>
                                        </div>
                                        {fromSchemeDetails.is_activity_active == '1' && ((fromSchemeDetails.monitoring_level == "3" && authData.user.department_details.department_level != 'T1') || (fromSchemeDetails.monitoring_level != "3")) && <div className="col-md-12" style={{ marginTop: '20px' }}>
                                            <table className="table table-bordered" style={{ tableLayout: 'auto' }}>
                                                <thead className="text-center-custom">
                                                    <tr>
                                                        <th scope="col" width="150px">Activity</th>
                                                        <th scope="col" width="150px">Subactivity</th>
                                                        <th scope="col" width="300px">Available Balance</th>
                                                        <th scope="col" width="300px">Amount</th>
                                                        <th scope="col" width="150px">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-center-custom">
                                                    {fromSubheadData?.activity.map((activityData, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <select
                                                                    className="form-control"
                                                                    name='activity_id'
                                                                    value={activityData.activity_id}
                                                                    onChange={(e) => handleFromActivityChange(index, e)}
                                                                    disabled={!fromSubheadData.from_subhead_id}>
                                                                    <option value="">---Select---</option>
                                                                    {activity.map(activity => {
                                                                        // console.log("subheadActivity.length", subheadActivity.current);
                                                                        if (fromSubheadData?.activity_budget && fromSubheadData?.activity_budget.length) {
                                                                            const exists = fromSubheadData?.activity_budget.find(e => e.subhead_activity_id == activity.id);
    
                                                                            if (exists) {
                                                                                return (
                                                                                    <option key={activity.id} value={activity.id}>{activity.activity}</option>
                                                                                )
                                                                            }
                                                                        }
                                                                        else {
                                                                            if (fromSchemeDetails.monitoring_level == "2") {
                                                                                return (
                                                                                    <option key={activity.id} value={activity.id}>{activity.activity}</option>
                                                                                )
                                                                            }
                                                                        }
                                                                    })}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select
                                                                    className="form-control"
                                                                    name='subactivity_id'
                                                                    value={activityData.subactivity_id}
                                                                    onChange={(e) => handleFromActivityChange(index, e, activityData.activity_id)}
                                                                    disabled={!fromSubheadData.from_subhead_id}>
                                                                    <option value="">---Select---</option>
                                                                    {findSubActivitiesById(activityData.activity_id).map(e => {
                                                                        if (fromSubheadData?.activity_budget && fromSubheadData?.activity_budget.length) {
                                                                            const exists = fromSubheadData?.activity_budget.find(item => item.subhead_subactivity_id == e.id);
                                                                            if (exists) {
                                                                                return (
                                                                                    <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                                )
                                                                            }
                                                                        }
                                                                        else {
                                                                            if (fromSchemeDetails.monitoring_level == "2") {
                                                                                return (
                                                                                    <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                                )
                                                                            }
                                                                        }
                                                                    }
                                                                        //<option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                    )}
                                                                </select>
                                                            </td>
                                                            <td>{fromSubheadData?.activity_budget && fromSubheadData?.activity_budget.length != 0 && fromSubheadData?.activity_budget.find(e =>
                                                                e.subhead_activity_id === activityData.activity_id &&
                                                                e.subhead_subactivity_id === activityData.subactivity_id
                                                            )?.budget}</td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    name='budget'
                                                                    placeholder="Enter Release Budget"
                                                                    value={activityData?.budget}
                                                                    disabled={!fromSubheadData.from_subhead_id || id}
                                                                    onChange={(e) => handleFromActivityChange(index, e)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-primary mx-2" onClick={(e) => addNewActivity('from')}>
                                                                    <AddIcon />
                                                                </button>
                                                                {index != 0 && <button type="button"
                                                                    className="btn btn-danger mx-2"
                                                                    onClick={(e) => deleteNewActivity(index, 'from')}>
                                                                    <DeleteForever /></button>}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>}
                                        <div className='col-md-12'><button type="button" onClick={handleFromSubheadSave} className='btn btn-primary mt-2'>Add Subhead</button></div>
                                        <div className="col-md-12">
                                            <hr />
                                        </div>
                                        <div className="col-md-12">
                                            <h6 className="text-center mb-4" style={{ fontWeight: 'bold' }}>From Subhead</h6>
                                            <table className="table table-responsive" style={{ tableLayout: 'auto' }}>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Scheme Name</th>
                                                        <th>Financial Year</th>
                                                        <th>Subhead Name</th>
                                                        <th>Subhead Balance</th>
                                                        <th>Reappropriation Amount</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
    
                                                <tbody>
                                                    {
                                                        fromSubheadsList.length > 0 ?
                                                            fromSubheadsList.map((e, index) => {
                                                                const financial_year = fy_list.find(y => y.id == e.financial_id);
                                                                const isRowExpanded = expandedIndex === index; // Check if this row is expanded based on its index
    
                                                                return (
                                                                    <React.Fragment key={e.from_subhead_id}>
                                                                        <tr>
                                                                            <td><span onClick={() => toggleNestedTable(index)} style={{ cursor: 'pointer' }}>
                                                                                {isRowExpanded ? <BiChevronUp /> : <BiChevronDown />}
                                                                            </span></td>
                                                                            <td>{fromSchemeDetails.scheme_name}</td>
                                                                            <td>{fromSchemeDetails.financial_year}</td>
                                                                            <td>{e.subhead_name}</td>
                                                                            <td>{e.subhead_balance}</td>
                                                                            <td>{e.from_reappropriation_amount}</td>
                                                                            <td>
                                                                                {/* <span onClick={() => removeSubhead(e.from_scheme_id, e.from_subhead_id)}>
                                                                                        <BiTrash />
                                                                                    </span> */}
                                                                                <button type="button"
                                                                                    className="btn btn-danger mx-2"
                                                                                    onClick={(e) => removeSubhead(e.scheme_id, e.from_subhead_id, 'from')}>
                                                                                    <DeleteForever /></button>
                                                                            </td>
                                                                        </tr>
    
                                                                        {/* Nested Table */}
                                                                        {isRowExpanded && (
                                                                            <tr>
                                                                                <td colSpan={6}>
                                                                                    <table className="table table-bordered mt-2">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Activity</th>
                                                                                                <th>Subactivity</th>
                                                                                                <th>Available Balance</th>
                                                                                                <th>Amount</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {e.activity && e.activity.length > 0 ? (
                                                                                                e.activity.map((activityItem, index) => (
                                                                                                    <tr key={index}>
                                                                                                        <td>
                                                                                                            <select
                                                                                                                className="form-control"
                                                                                                                name="activity_id"
                                                                                                                value={activityItem.activity_id}
                                                                                                                disabled={true}
                                                                                                            >
                                                                                                                <option value="">---Select---</option>
                                                                                                                {activity.map(a => (
                                                                                                                    <option key={a.id} value={a.id}>{a.activity}</option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <select
                                                                                                                className="form-control"
                                                                                                                name="subactivity_id"
                                                                                                                value={activityItem.subactivity_id}
                                                                                                                disabled={true}
                                                                                                            >
                                                                                                                <option value="">---Select---</option>
                                                                                                                {findSubActivitiesById(activityItem.activity_id).map(sa => (
                                                                                                                    <option key={sa.id} value={sa.id}>{sa.subactivity}</option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                        </td>
                                                                                                        <td>{e?.activity_budget && e?.activity_budget.length != 0 && e?.activity_budget.find(e =>
                                                                                                            e.subhead_activity_id === activityItem.activity_id &&
                                                                                                            e.subhead_subactivity_id === activityItem.subactivity_id
                                                                                                        )?.budget}</td>
                                                                                                        <td>{activityItem?.budget}</td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            ) : (
                                                                                                <tr>
                                                                                                    <td colSpan={4} className="text-center">No Details Available</td>
                                                                                                </tr>
                                                                                            )}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </React.Fragment>
                                                                );
                                                            })
                                                            : <tr><td colSpan={6} className="text-center">No Data</td></tr>
                                                    }
                                                </tbody>
    
                                                <tfoot>
                                                    <tr>
                                                        <th colSpan={5}>Total Reappropriation Amount: </th>
                                                        <th>{totalAmount.current}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
    
    
                                            <div className="mt-2 text-center">
                                                <Button type="submit" variant="primary" onClick={handleSubmit}> Save Budget Reappropriate </Button>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </Form> */}
                        </div>
                    </div>
                </div>
                <Modal show={showConfirm} onHide={() => handleConfirm(false)} centered>
                    <Modal.Header closeButton={() => handleConfirm(false)}>
                        <Modal.Title>Confirm</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>If you want to change the scheme, you will lose any filled data. Are you sure you want to continue?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => confirmSubmit()}>
                            YES
                        </Button>
                        <Button variant="danger" onClick={() => handleConfirm(false)}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal></>
        )
}