import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import DataTable from 'react-data-table-component';
import {useLocation} from "react-router-dom";
import {getTDSReports} from "../../helper/Api";
import {AddCommasToAmount} from "../../helper/Utils";
import {Col, Form, Row} from "react-bootstrap";

const TDSReportList = ({data}) => {
    const {authData} = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    let {state} = useLocation();
    // const [data, setData] = useState(tableData);

    // const [fromDate, setFromDate] = useState('');
    // const [toDate, setToDate] = useState('');


    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'Beneficiary\n' +
                'Name',
            selector: (row) => <b>{row.company_name || ''}</b>,
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Voucher No',
            selector: (row) => <b>{row.voucher_no}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Voucher\n' +
                'Date',
            selector: (row) => <b>{row.voucher_date}</b>,
            sortable: true,
            width: "150px",
            right: false
        },

        {
            name: 'INVOICE\n' +
                'AMOUNT',
            selector: (row) => <b>{row.payable_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Taxable\n' +
                'Amount',
            selector: (row) => <b>{row.taxable_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'TDS IT',
            selector: (row) => <b>{row?.tds_it_amount}</b>,
            sortable: true,
            width: "150px",
            right: false
        },{
            name: 'Code',
            selector: (row) => <b>{row?.code}</b>,
            sortable: true,
            width: "150px",
            right: false
        },{
            name: 'BSR Code',
            selector: (row) => <b>{row?.bsr_code}</b>,
            sortable: true,
            width: "150px",
            right: false
        },{
            name: 'Challan Date',
            selector: (row) => <b>{row?.challan_date}</b>,
            sortable: true,
            width: "150px",
            right: false
        },{
            name: 'Challan No',
            selector: (row) => <b>{row?.challan_no}</b>,
            sortable: true,
            width: "150px",
            right: false
        },{
            name: 'Diposited\n' +
                'Amount',
            selector: (row) => <b>{row?.tds_challan_value}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Beneficiary\n' +
                'PAN No.',
            selector: (row) => <b>{row.pan_no}</b>,
            sortable: true,
            width: "200px",
            wrap: true

        },
    ];

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };


    // const calculateTotalAmount = (propertyName) => {
    //     let total = 0;
    //     data.forEach((row) => {
    //         const depositAmount = Number(parseFloat(row[propertyName]));
    //         if (!isNaN(depositAmount)) {
    //             total += depositAmount;
    //         }
    //     });
    //     return total.toFixed(2);
    // };

    // const totalInvoiceAmount = calculateTotalAmount('tds_it_amount');

    // const footerRow = {
    //     isFooter: true,
    //     selectableRowsSingle: false,
    //     voucher_no: <b>Total</b>,
    //     tds_it_amount: <b>{AddCommasToAmount(totalInvoiceAmount)}</b>,
    // };

    // const fetchChallans = async () => {
    //     try {
    //         const resp = await getTDSReports({
    //             from_date: fromDate || '',
    //             to_date: toDate || '',
    //             searchStr: searchStr,
    //             dateColumn: dateColumn
    //         });
    //         if (resp.data.status) {
    //             setData(resp.data.list);
    //         }
    //     } catch (error) {
    //         setData([]);
    //     }
    // }

    // useEffect(() => {
    //     fetchChallans();
    // }, [fromDate, toDate, searchStr, dateColumn])



    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                /*paginationComponent={() => (
                    <CustomPagination
                        rowsPerPage={rowsPerPage}
                        rowCount={data.length}
                        currentPage={currentPage}
                        onChangePage={handleChangePage}
                    />
                )}*/
            />
        </div>
    )
}

export default TDSReportList
