import React, { useState, useEffect, useRef } from 'react'
import {
    get_schemes,
    get_departments,
    get_schemes_by_fy,
    get_financial_year,
    UploadAllocateBudgetDoc,
    getAllocateBudgetList,
    getActivity,
    getSubActivity,
    approve_allocate_budget,
    get_departmentDetail
} from '../../../helper/Api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AddBudgetPreview from '../Model/AddBudgetPreview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import './ViewAllocateBudget.css';
const ViewBudget = () => {
    const navigate = useNavigate();
    const { viewId, approveId } = useParams();
    const [showPreview, setShowPreview] = useState(false);
    const [fy_list, set_fy_list] = useState([]);
    const [schemeList, setSchemesList] = useState([]);
    const [activity, setActivity] = useState([]);
    const [subActivity, setSubActivity] = useState([]);
    const [scheme, setScheme] = useState({});
    const [subHead, setSubHead] = useState([]);
    const [allocatesubHead, setallocateSubHead] = useState([]);
    const [department, setDepartment] = useState([]);
    const { authData } = useSelector((state) => state.authData);
    const subheadBudgetTotal = useRef({
        allocateProvision: 0,
        allocateRelease: 0,
    });
    const [expandedIndex, setExpandedIndex] = React.useState(null);
    const [formData, setFormData] = useState({
        id: "",
        budget_order_no: "",
        budget_order_date: "",
        provision: 0,
        release: 0,
        scheme_id: null,
        financial_year_id: null,
        supporting_attachment: [],
        order_attachment: [],
        l1_remark: "",
        l2_remark: "",
        l3_remark: "",
        l2_status: "",
        l3_status: "",
        total_scheme_provisional_allocation: 0,
        total_scheme_released_allocation: 0,
        subheads: [],
        department_Array: [
            {
                department_id: "",
                department_level: "",
                department_name: "",
                subHead: [{
                    subhead_id: "",
                    name: "",
                    code: "",
                    provisional_budget: 0,
                    release_budget: 0,
                    balance: 0,
                    approveBudget: 0,
                    santioned: 0,
                    release_Balance: 0,
                    release_Balance: 0,
                    utilised_budget: 0,
                    activity: [{
                        activity_id: '', subactivity_id: '', provisional_budget: "",
                        budget: "",
                    }],
                    activities: [],
                }],
            }],
        parent_department_id: authData.user.department_id,
        budget_order_doc_url: "",
        budget_supporting_doc_url: "",
    });
    const fetchFinancialYears = async () => {
        const fy = await get_financial_year();
        if (fy.data.status) {
            set_fy_list(fy.data.list)
        }
    }
    const dispatch = useDispatch();
    useEffect(() => {
        // fetchDepartments();
        fetchActivity();
        fetchSubActivity();
        fetchSchemes({});
        fetchFinancialYears();
        fetchAllocateBudget();
    }, [viewId])
    const fetchSchemes = async () => {
        try {
            const resp = await get_schemes({ approved: true });
            if (resp.data.status) {
                setSchemesList(resp.data.schemes);
            }
        } catch (error) {
            setSchemesList([]);
        }
    }
    const schemeDetailByfy = async (scheme_id, financial_year_id) => {
        try {
            const resp = await get_schemes_by_fy(scheme_id, financial_year_id);
            if (resp.data.status) {
                if (resp.data.schemes.length != 0) {
                    setScheme(resp.data.schemes[0]);
                    setSubHead(resp.data.schemes[0].sub_heads_list);
                }
            }
        } catch (error) {
            // console.log(error);
            setScheme({})
        }
    }
    // const fetchDepartments = async () => {
    //     try {
    //         let newDepartmentLevel = authData.user.department_details.department_level;
    //         if (newDepartmentLevel === "T1") {
    //             newDepartmentLevel = "T2";
    //         } else if (newDepartmentLevel === "T2") {
    //             newDepartmentLevel = "T3";
    //         } else if (newDepartmentLevel === "T3") {
    //             newDepartmentLevel = "T4";
    //         }
    //         const departments = await get_departments({ department_level: newDepartmentLevel });
    //         if (departments.data.status) {
    //             setDepartment(departments.data.result);
    //         }
    //     } catch (error) {
    //         console.log("departments", error);
    //     }
    // }
    const fetchAllocateBudget = async () => {
        try {
            let subheadArray = [];
            let newDepartmentLevel = authData.user.department_details.department_level;
            if (newDepartmentLevel === "T1") {
                newDepartmentLevel = "T2";
            } else if (newDepartmentLevel === "T2") {
                newDepartmentLevel = "T3";
            } else if (newDepartmentLevel === "T3") {
                newDepartmentLevel = "T4";
            }
            
            // if(departments.data.status) {
                const { data } = await getAllocateBudgetList({ id: viewId });
                const departments = await get_departments({ parent_department_id: data.result[0].parent_department_id });
                setDepartment(departments.data.result);
                let totalProvisionalBudgetOverall = 0;
                let totalReleaseBudgetOverall = 0;
                let department_Array = [];
                const resp = await get_schemes_by_fy(data.result[0].scheme_id, data.result[0].financial_year_id);
                const parent_departmentDetails = await get_departmentDetail({ id: data.result[0].parent_department_id });
                if (resp.data.status) {
                    if (resp.data.schemes.length != 0) {
                        resp.data.schemes[0]['departmentName'] = parent_departmentDetails.data.result[0].name;
                        setScheme(resp.data.schemes[0]);
                        setSubHead(resp.data.schemes[0].sub_heads_list);
                        subheadArray = resp.data.schemes[0].sub_heads_list;
                    }
                }
                data.result[0].budget_details.forEach(item => {
                    let departmentObj = departments.data.result.find(d => d.id === item.department_id);
                    let departmentCheck = department_Array.find(d => d.department_id === item.department_id);
                    if (!departmentCheck) {
                        departmentCheck = {
                            department_id: item?.department_id,
                            department_level: departmentObj?.department_level,
                            department_name: departmentObj?.name,
                            subHead: []
                        };
                        department_Array.push(departmentCheck);
                    }
                    let totalProvisionalBudget=0;
                    let totalReleaseBudget=0;
                    let Subheadactivities = [];
                    const subheadActivity = subheadArray.find(d => d.id === item.subhead_id);
                    if (resp.data.schemes[0].monitoring_level && resp.data.schemes[0].monitoring_level !="2") {
                        Subheadactivities = item.activities.map(activity => {
                            const subheadItems = subheadActivity.activities.find(d => d.subhead_activity_id === activity.subhead_activity_id && d.subhead_subactivity_id === activity.subhead_subactivity_id);
                            totalProvisionalBudget += parseFloat(activity.provisional_budget);
                            totalReleaseBudget += parseFloat(activity.budget);
                            return {
                                activity_id: activity.subhead_activity_id,
                                subactivity_id: activity.subhead_subactivity_id,
                                provisional_budget: activity.provisional_budget,
                                budget: activity.budget,
                                subhead_provisional_budget: subheadItems?.provisional_budget | 0,
                                subhead_budget: subheadItems?.budget | 0,
                            };
                        });
                    }
                    else {
                        Subheadactivities = [];
                        totalProvisionalBudget += parseFloat(item.provisional_budget);
                        totalReleaseBudget += parseFloat(item.budget);
                    }
                    const subHead = {
                        id: item.id,
                        subhead_id: item.subhead_id,
                        name: subheadActivity.name,
                        code: subheadActivity.code,
                        provisional_budget: subheadActivity.provisional_budget,
                        budget: subheadActivity.budget | 0,
                        approveBudget: subheadActivity?.approveBudget | 0,
                        utilised_budget: subheadActivity?.utilised_budget | 0,
                        santioned: subheadActivity?.santioned | 0,
                        release_Balance: subheadActivity?.release_Balance | 0,
                        balance: subheadActivity.balance,
                        allocate_provision: totalProvisionalBudget,
                        allocate_release: totalReleaseBudget,
                        activity: Subheadactivities,
                        activities: item.activities,
                        allocated_budget_id: item.allocated_budget_id,
                    };
                    departmentCheck.subHead.push(subHead);
                    totalProvisionalBudgetOverall += parseFloat(totalProvisionalBudget);
                    totalReleaseBudgetOverall += parseFloat(totalReleaseBudget);
                });
                setallocateSubHead(data.result[0].budget_details);
                setFormData({
                    id: data.result[0].id,
                    budget_order_no: data.result[0].budget_order_no,
                    budget_order_date: data.result[0].budget_order_date,
                    scheme_id: data.result[0].scheme_id,
                    financial_year_id: data.result[0].financial_year_id,
                    supporting_attachment: [],
                    order_attachment: [],
                    provision: data.result[0]?.total_scheme_provisional_allocation,
                    release: data.result[0]?.total_scheme_released_allocation,
                    l1_remark: data.result[0].l1_remark,
                    l2_remark: data.result[0].l2_remark,
                    l3_remark: data.result[0].l3_remark,
                    l2_status: data.result[0].l2_status,
                    l3_status: data.result[0].l3_status,
                    total_scheme_provisional_allocation: data.result[0].total_scheme_provisional_allocation,
                    total_scheme_released_allocation: data.result[0].total_scheme_provisional_allocation,
                    subheads: [],
                    budget_order_doc_url: data.result[0].budget_order_doc_url,
                    budget_supporting_doc_url: data.result[0].budget_supporting_doc_url,
                    department_Array: department_Array,
                })
                subheadBudgetTotal.current = {
                    allocateProvision: totalProvisionalBudgetOverall,
                    allocateRelease: totalReleaseBudgetOverall
                };
            // }
            console.log('formData', formData);
        } catch (error) {
            console.log("allocate budget", error);
        }
    }
    const fetchActivity = async () => {
        try {
            const resp = await getActivity();
            if (resp.data.status) {
                setActivity(resp.data.result);
            }
        } catch (error) {
            setActivity([]);
        }
    }
    const fetchSubActivity = async () => {
        try {
            const resp = await getSubActivity();
            if (resp.data.status) {
                setSubActivity(resp.data.result);
            }
        } catch (error) {
            setSubActivity([]);
        }
    }
    const findSubActivitiesById = (id) => {
        let listData = subActivity;
        return listData.filter(subActivity => subActivity.activity_id === id);
    };
    const handleSubmit = async (status) => {
        try {
            // anand sir please write code of approve case
            let payload = {
                l2_remark: formData.l2_remark,
                l3_remark: formData.l3_remark,
                l2_status: formData.l2_status,
                l3_status: formData.l3_status
            };
            if (authData.user.user_type.toLowerCase() == 'l2') {
                payload.l2_status = status
            }
            if (authData.user.user_type.toLowerCase() == 'l3') {
                payload.l3_status = status
            }

            const response = await approve_allocate_budget(payload, formData.id);
            if (response.data.status) {
                // rajat please add close popup code and show success or error message
                setShowPreview(false);
                navigate('/allocate-budgets');
            }
        } catch (error) {
            console.log(error);
        }
    }
    const previewhandler = (e) => {
        e.preventDefault();
        setShowPreview(true);
    };
    const handleFormData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({
            ...formData,
            [name]: value,
        });
        console.log(formData);
    }
    // Function to toggle the collapse state
    const toggleCollapse = (index) => {
        // If the clicked index is already expanded, collapse it, otherwise set it as the expanded index
        setExpandedIndex(expandedIndex === index ? null : index);
    };


    const getProvisionAndRelease = (array, subhead_id) => {
        let totalProvisionalBudget = 0;
        let totalReleaseBudget = 0;
        let total_provision = 0;
        let total_release = 0;
        array.forEach(item => {
            if (subhead_id) {
                if (item.subhead_id === subhead_id) {
                    totalProvisionalBudget += parseFloat(item.provisional_budget);
                    totalReleaseBudget += parseFloat(item.budget);
                }
            }
            else {
                total_provision += parseFloat(item.provisional_budget);
                total_release += parseFloat(item.budget);
            }
        });
        return { totalProvisionalBudget, totalReleaseBudget, total_provision, total_release };
    }
    return (
        <>
            <AddBudgetPreview showPreview={showPreview} setShowPreview={setShowPreview} formData={formData}
                handleSubmit={handleSubmit} scheme={scheme} subheadsList={subHead} financialYears={fy_list}
                department={department} activity={activity} subActivity={subActivity} subheadBudgetTotal={subheadBudgetTotal} pageType={'View'} />
            <div>
                <div className="p-3">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4>View Allocate Budget</h4>
                            </div>
                            <div className="card p-3">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                        <div className="row">
                                            <div className="col-sm-12 p-2">
                                                <div className="row">
                                                    <div className="col-sm-3 offset-sm-3">
                                                        <select
                                                            className="form-control"
                                                            name="scheme_id"
                                                            value={formData.scheme_id}
                                                            disabled={true}>
                                                            <option>Scheme Code</option>
                                                            {
                                                                schemeList.map((option) => {
                                                                    return (
                                                                        <option value={option?.id}>{option?.code}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <select
                                                            className="form-control"
                                                            name="financial_year_id"
                                                            value={formData.financial_year_id}
                                                            disabled={true}>
                                                            <option value="">Financial Year</option>
                                                            <option value="">---select year---</option>
                                                            {
                                                                fy_list.map((fy) => {
                                                                    return (
                                                                        <option
                                                                            value={fy.id}
                                                                        // selected={fy.id == fy_id ? true : false}
                                                                        >{fy.year}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {formData.scheme_id && formData.financial_year_id && <div className="card p-3 mt-3">
                                <div className="table-responsive">
                                    <table className="table" style={{ borderColor: 'gray', marginBottom: '30px' }}>
                                        <thead>
                                            <tr>
                                                <th colSpan="4" style={{ textAlign: 'center', fontSize: '18px', padding: '10px', background: '#5684e4', color: '#fff' }}>Budget Order Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td scope="col" style={{ width: '25%', textAlign: 'center' }}><strong>Budget Order Ref No</strong></td>
                                                <td scope="col" style={{ width: '25%', textAlign: 'center' }}>{formData.budget_order_no}</td>
                                                <td scope="col" style={{ width: '25%', textAlign: 'center' }}><strong>Budget Order Date</strong></td>
                                                <td scope="col" style={{ width: '25%', textAlign: 'center' }}>{formData.budget_order_date}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-8 offset-md-2 mb-3">
                                    <div className="row">
                                        <div className="col-sm-6 d-flex align-items-center">
                                            {formData?.budget_order_doc_url != null && formData?.budget_order_doc_url && <a className="mt-2 mx-2 btn btn-primary" href={formData?.budget_order_doc_url}
                                                target='_blank'>Order Attachment</a>}
                                        </div>
                                        <div className="col-sm-6 d-flex align-items-center">
                                            {formData?.budget_supporting_doc_url != null && formData?.budget_supporting_doc_url ? <a className="mt-2 mx-2 btn btn-primary" href={formData?.budget_supporting_doc_url}
                                                target='_blank'>supporting_attachment</a> : <p className="mt-1">No Supporting Doc Attached</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered" style={{ borderColor: 'gray', marginBottom: '30px' }}>
                                        <thead>
                                            <tr>
                                                <th colspan="4" style={{ textAlign: 'center', fontSize: '18px', padding: '10px', background: '#5684e4', color: '#fff' }}>Scheme Details</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="col">Financial Year</th>
                                                <th scope="col" colspan="3">
                                                    <select
                                                        className="form-control"
                                                        name="financial_year_id"
                                                        value={formData.financial_year_id}
                                                        style={{
                                                            width: '300px',
                                                            margin: '0px auto',
                                                            textAlign: 'center'
                                                        }}
                                                        disabled={true}>
                                                        <option value="">Financial Year</option>
                                                        {
                                                            fy_list.map((fy) => {
                                                                return (
                                                                    <option
                                                                        value={fy.id}
                                                                    >{fy.year}</option>
                                                                );
                                                            })
                                                        }
                                                    </select></th>
                                            </tr>
                                            <tr>
                                                <th scope="col">Department Name</th>
                                                <th scope="col">Scheme Code</th>
                                                <th colspan="2">Scheme Name</th>
                                            </tr>
                                            <tr>
                                                <td>{scheme.departmentName}</td>
                                                <td>{scheme.code}</td>
                                                <td colspan="2">{scheme.name}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Type of Scheme</th>
                                                <th scope="col"></th>
                                                <th scope="col">Total Provisioned Allocation</th>
                                                <th scope="col">Total Released Allocation</th>
                                            </tr>
                                            <tr>
                                                <td scope="col">{scheme.type}</td>
                                                <td scope="col"></td>
                                                <td>{subheadBudgetTotal.current.allocateProvision || 0}</td>
                                                <td>{subheadBudgetTotal.current.allocateRelease || 0}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered" style={{ borderColor: 'gray', marginBottom: '30px' }}>
                                        <thead>
                                            <tr>
                                                <th colspan="4" style={{ textAlign: 'center', fontSize: '18px', padding: '10px', background: '#5684e4', color: '#fff' }}>Subhead Details</th>
                                            </tr>
                                            <tr>
                                                <th >Subhead Code</th>
                                                <th >Subhead Name</th>
                                                <th >Provisional Allocation</th>
                                                <th>Released Allocation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subHead.map((subhead, index) => {
                                                const isMatchingSubhead = allocatesubHead.find(s => s.subhead_id === subhead.id);
                                                if (isMatchingSubhead) {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{subhead.code}</td>
                                                            <td>{subhead.name}</td>
                                                            <td>{getProvisionAndRelease(allocatesubHead, subhead.id).totalProvisionalBudget}</td>
                                                            <td>{getProvisionAndRelease(allocatesubHead, subhead.id).totalReleaseBudget}</td>
                                                        </tr>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Total Allocation</th>
                                                <td>{getProvisionAndRelease(allocatesubHead,).total_provision}</td>
                                                <td>{getProvisionAndRelease(allocatesubHead,).total_release}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="table-responsive">
                                    {formData.department_Array.map((item, index) => (<table className="table table-bordered" style={{ borderColor: 'gray', marginBottom: '30px' }}>
                                        <thead>
                                            {
                                                (index === 0) ? <tr>
                                                <th colSpan="5" style={{ textAlign: 'center', fontSize: '18px', padding: '10px', background: '#5684e4', color: '#fff' }}>Allocation Details</th>
                                            </tr> : ''
                                            }
                                            
                                            <tr>
                                                <th>Level</th>
                                                <th>Department Code</th>
                                                <th colSpan="3">Department Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{item.department_level}</td>
                                                <td><select class="form-control" id="dropdown1" value={item.department_id} disabled={true}>
                                                    <option value="">---Select---</option>
                                                    {department.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.code}
                                                        </option>
                                                    ))}
                                                </select></td>
                                                <td colSpan="3">{item.department_name}</td>
                                            </tr>
                                            <tr>
                                                <th >{expandedIndex === index ? (
                                                    <ExpandLessIcon style={{ marginLeft: '5px' }} onClick={() => toggleCollapse(index)} />
                                                ) : (
                                                    <ExpandMoreIcon style={{ marginLeft: '5px' }} onClick={() => toggleCollapse(index)} />
                                                )} Subhead Code</th>
                                                <th style={{ width: '200px' }}>Activity</th>
                                                <th style={{ width: '300px' }}>Sub-Activity</th>
                                                <th>Provisioned Allocation</th>
                                                <th>Released Allocation</th>
                                            </tr>
                                            {expandedIndex === index && item.subHead.map((subhead, i) => (
                                                <React.Fragment key={i}>
                                                    {subhead.activities.map((activityData, j) => (
                                                        <tr key={j}>
                                                            <td>
                                                                <select
                                                                    className="form-control"
                                                                    name="code"
                                                                    value={subhead.subhead_id}
                                                                    disabled={true}
                                                                >
                                                                    <option value="">---Select---</option>
                                                                    {subHead.map((option) => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.code}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                            <td><select
                                                                className="form-control"
                                                                name='activity_id'
                                                                value={activityData.subhead_activity_id}
                                                                disabled={true}
                                                                style={{ width: '200px' }}
                                                            >
                                                                <option value="">---Select---</option>
                                                                {activity.map(activity => {
                                                                    const exists = subhead.activities.find(e => e.subhead_activity_id == activity.id);
                                                                    if (exists) {
                                                                        return (
                                                                            <option key={activity.id} value={activity.id}>{activity.activity}</option>
                                                                        )
                                                                    }
                                                                })}
                                                            </select></td>
                                                            <td><select
                                                                className="form-control"
                                                                name='subactivity_id'
                                                                value={activityData.subhead_subactivity_id}
                                                                disabled={true}
                                                                style={{ width: '300px' }}
                                                            >
                                                                <option value="">---Select---</option>
                                                                {findSubActivitiesById(activityData.subhead_activity_id).map(e => {
                                                                    const exists = subhead.activities.find(ea => ea.subhead_activity_id == activityData.subhead_activity_id && ea.subhead_subactivity_id == e.id);
                                                                    if (exists) {
                                                                        return (
                                                                            <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                        )
                                                                    }
                                                                })}
                                                            </select></td>
                                                            <td>{activityData.provisional_budget | 0}</td>
                                                            <td>{activityData.budget | 0}</td>
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                            <tr>
                                                
                                                {item.subHead.map((subhead, i) => (
                                                    <>
                                                    <th scope="col">{subhead.code}</th>
                                                    <th scope="col"></th>
                                                    <th scope="col">Total Allocation</th>
                                                    <td>{subhead.allocate_provision}</td>
                                                    <td>{subhead.allocate_release}</td>
                                                    </>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>))}
                                </div>
                                <div className="col-md-10 offset-md-1 mb-3">
                                    <div className="row">
                                        <div className="col-sm-12 p-2">
                                            <div className="form-group">
                                                <label><strong>L1 Remark</strong></label>
                                                <textarea id="remarl1" className="form-control" rows="2" name="l1_remark" value={formData.l1_remark} disabled={true}></textarea>
                                            </div>
                                        </div>
                                        {(authData.user.user_type == 'l2' || authData.user.user_type == 'l3') && <div className="col-sm-12 p-2">
                                            <div className="form-group">
                                                <label><strong>L2 Remark *</strong></label>
                                                <textarea id="remarl2" className="form-control" rows="2" name="l2_remark" value={formData.l2_remark} onChange={handleFormData}></textarea>
                                            </div>
                                        </div>}
                                        {authData.user.user_type == 'l3' && <div className="col-sm-12 p-2">
                                            <div className="form-group">
                                                <label><strong>L3 Remark *</strong></label>
                                                <textarea id="remarl3" className="form-control" rows="2" name="l3_remark" value={formData.l3_remark} ></textarea>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                {(authData.user.user_type == 'l2' && formData.l2_status == null ) || (authData.user.user_type == 'l3' && formData.l3_status == null) ? <div class="row mt-3 justify-content-center">
                                    <div className="col-auto d-flex justify-content-center">
                                        <button type="button" className="btn btn-danger mx-2" onClick={previewhandler}>
                                            Reject
                                        </button>
                                        <button type="button" className="btn btn-primary mx-2" onClick={previewhandler}>
                                            Approve
                                        </button>
                                    </div>
                                </div> : ''}
                                <div className="col-auto d-flex justify-content-center">
                                    {((formData.l2_status == 1 && authData.user.user_type == 'l2') || (formData.l3_status == 1 && authData.user.user_type == 'l3')) && <button type="button" className='btn btn-primary mx-2' disabled="true">Already Approved</button>}
                                    {((formData.l2_status == 0 && authData.user.user_type == 'l2') || (formData.l3_status == 0 && authData.user.user_type == 'l3')) && <button type="button" className='btn btn-danger mx-2' disabled="true">Already Rejected</button>}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ViewBudget
