import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import PrivateRoute from './route/PrivateRoute';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Auth/Login/Login';
import LoginOtp from './pages/Auth/Login/LoginOtp';
import Schemes from './pages/BudgetAllocation/Schemes';
import AddBudget from './pages/BudgetAllocation/AddBudget';
import AddNewScheme from './pages/BudgetAllocation/AddNewScheme';
import BeneficiariesMgt from './pages/BeneficiariesMgt/BeneficiariesMgt';
import InvoiceList from './pages/Disbursment/InvoiceList';
import AddVoucher from './pages/Disbursment/AddVoucher/AddVoucher';
import FundInvoice from './pages/FundRelease/Invoice/FundInvoice';
import Users from "./pages/Users/Users.jsx";
import AddBeneficiary from './pages/BeneficiariesMgt/AddBeneficiary';
import AddUser from './pages/Users/AddUser';
import Approval from "./pages/FundRelease/Approval/Approval.jsx"
import PaymentStatus from './pages/FundRelease/PaymentStatus/PaymentStatus';
import ProceedForPayment from './pages/FundRelease/PaymentStatus/ProceedForPayment';
import PaymentReport from './pages/Reconciliation/PaymentReport';
import { useSelector } from 'react-redux';
import EditUser from './pages/Users/EditUser';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SchemeDetails from './pages/BudgetAllocation/SchemeDetails';
import ViewScheme from './components/BudgetAllocation/Schemes/ViewScheme';
import Budgets from './pages/BudgetAllocation/Budgets';
import ViewBudgetForm from './components/BudgetAllocation/Schemes/ViewBudgetForm';
import ViewBeneficiary from './pages/BeneficiariesMgt/ViewBeneficiary';
import EditScheme from './pages/BudgetAllocation/EditScheme';
import EditBudget from './pages/BudgetAllocation/EditBudget';
import EditBeneficiary from './pages/BeneficiariesMgt/EditBeneficiary';
import { useEffect, useState } from 'react';
import Challan from "./pages/Reconciliation/challan/Challan";
import AddChallan from "./pages/Reconciliation/challan/AddChallan";
import InterestReport from "./pages/Reports/Interest";
import UpdateInvoice from './pages/FundRelease/Invoice/UpdateInvoice';
import BeneficiaryReport from "./pages/Reports/Beneficiary";
import SchemeSubHeadReport from "./pages/Reports/SchemeSubHead";
import TDSit from "./pages/Reconciliation/TDSit/TDSit";
import AddTDSITChallan from "./pages/Reconciliation/TDSit/AddTDSITChallan";
import TDSitReport from "./pages/Reports/TDSitReport";
import { setAuthToken } from "./helper/Api";
import GIS from "./pages/Reconciliation/GIS/gis";
import NPS from "./pages/Reconciliation/NPS/nps";
import GST from "./pages/Reconciliation/GST/gst.jsx";
import OTHER from './pages/Reconciliation/Other/other.jsx';
import AddGISChallan from "./pages/Reconciliation/GIS/AddGISChallan";
import GISReport from "./pages/Reports/GISReport";
import GSTReport from "./pages/Reports/GSTReport";
import NPSReport from "./pages/Reports/NPSReport";
import OTHERReport from "./pages/Reports/OtherReport";
import AddOtherChallan from "./pages/Reconciliation/Other/AddOther";
import AddGSTChallan from "./pages/Reconciliation/GST/AddGST";
import AddNPSChallan from "./pages/Reconciliation/NPS/AddNPS";
import DepositoryListPage from './pages/Depository/DepositoryList.jsx';
import AddDepositoryPage from './pages/Depository/AddDepositoryPage.jsx';
import AddBeneficiaryAccountPage from './pages/BeneficiariesMgt/AddBeneficiaryAccount.jsx';
import AccumulatedDeduction from "./pages/Reconciliation/Accumulated/AccumulatedDeduction.jsx";
import AddBudgetReappropriatePage from './pages/BudgetReappropriate/AddBudgetReappropriate.jsx';
import BudgetReappropriateListPage from './pages/BudgetReappropriate/BudgetReappropriateList.jsx';
import AddBeneficiaryReimbursementPage from './pages/BeneficiaryReimbursement/AddBeneficiaryReimbursementPage.jsx';
import ViewBeneficiaryReimbursementPage from './pages/BeneficiaryReimbursement/ViewBeneficiaryReimbursementPage.jsx';
import BeneficiaryReimbursementListPage from './pages/BeneficiaryReimbursement/BeneficiaryReimbursementListPage.jsx';
import Departments from './pages/Multi/Multi-Department/Departments.jsx';
import AddDepartment from './pages/Multi/Multi-Department/AddDepartment.jsx';
import AllocateBudgetPage from './pages/Multi/BudgetAllocation/AllocateBudget.jsx';
import ViewBudgetPage from './pages/Multi/BudgetAllocation/ViewBudget.jsx';
import ActivityListPage from './pages/Multi/Department-Activity/Activity.jsx';
import AddActivityPage from './pages/Multi/Department-Activity/AddActivity.jsx';
import AddSubActivityPage from './pages/Multi/Department-SubActivity/AddSubactivity.jsx'
import SubActivityListPage from './pages/Multi/Department-SubActivity/SubactivityList.jsx'
import EditAllocateBudget from './pages/Multi/BudgetAllocation/EditAllocateBudget.jsx';
import BudgetPaymentListPage from './pages/Multi/BudgetAllocation/BudgetPaymentList.jsx';
import AllocateListPage from './pages/Multi/BudgetAllocation/BudgetAllocateList.jsx';
import AllocateApprovalListPage from './pages/Multi/BudgetAllocation/BudgetAllocateApprovalList.jsx';
import AddAccountPage from './pages/Multi/ChildAccount/AddAccount.jsx';
import ChildAccountListPage from './pages/Multi/ChildAccount/ChildAccount.jsx';
import AddScheneAccountPage from './pages/Multi/ChildSchemeAccount/AddSchemeAccount.jsx';
import SchemeAccountListPage from './pages/Multi/ChildSchemeAccount/ChildSchemeAccount.jsx';
import ForgetPassword from './pages/Auth/ForgetPassword/Forget.jsx';
import Ppa from './pages/Ppa/Ppa.jsx';
import MprReportPage from './pages/Multi/Mrp/MprReportPage.jsx';
import EditBeneficiaryReimbursementPage from './pages/BeneficiaryReimbursement/EditBeneficiaryReimbursementPage.jsx';
import Royalty from './pages/Reconciliation/Royalty/Royalty.jsx';
import AddRoyalty from './pages/Reconciliation/Royalty/AddRoyalty.jsx';
import ViewInvoice from './pages/Disbursment/ViewInvoice.jsx';
import DepartmentOtp from './pages/Auth/Login/DepartmentOtp.jsx';
import AddDepartmentUser from './pages/Users/AddDepartmentUser.jsx';
import ProfilePage from './pages/Users/Profile.jsx';
import DepartmentSwitch from './pages/Auth/Login/DepartmentSwitch.jsx';
import ViewUserPage from './pages/Users/ViewUser.jsx';
import LabourCess from './pages/Reconciliation/LabourCess/LabourCess.jsx';
import AddLabourCess from './pages/Reconciliation/LabourCess/AddLabourCess.jsx';
import RoyaltyApproval from './pages/Reconciliation/Royalty/RoyaltyApproval.jsx';
import RoyaltyReport from './pages/Reconciliation/Royalty/RoyaltyReport.jsx';
import LabourReport from './pages/Reconciliation/LabourCess/LabourReport.jsx';
import ViewRoyalty from './pages/Reconciliation/Royalty/ViewRoyalty.jsx';
import ViewLabourCess from './pages/Reconciliation/LabourCess/ViewLabourCess.jsx';
import AddAuthorityPage from './pages/Authority/AddAuthority.jsx';
import AuthorityListPage from './pages/Authority/Authoritys.jsx';
import XmlReport from './pages/Reports/XmlReport.jsx';
import AddBudgetSurrenderPage from './pages/BudgetSurrender/AddBudgetSurrender.jsx';
import BudgetSurrenderList from './pages/BudgetSurrender/BudgetSurrenderList.jsx';
import BudgetSurrenderPendingList from './pages/BudgetSurrender/BudgetSurrenderPendingList.jsx';
import ExpenseMprReportPage from './pages/Multi/Mrp/ExpenseMprReportPage.jsx';
import BeneficiaryPpa from './pages/Ppa/BeneficiaryPpa.jsx';
function App() {
    const { authData } = useSelector((state) => state.authData);

    // const isSignedIn=authData && authData.status ? authData.status:false;
    const isSignedIn = Boolean(sessionStorage.getItem("is_loggedIn"));

    const [lastActivity, setLastActivity] = useState(new Date().getTime());
    // const [token, setToken] = useState(JSON.parse(sessionStorage.getItem('token')));
    // Set token in headers on app load
    if (sessionStorage.getItem('token')) {
        setAuthToken(JSON.parse(sessionStorage.getItem('token')));
    }

    // Function to update the last activity timestamp
    const updateActivity = () => {

        setLastActivity(new Date().getTime());
    };

    // const handleBeforeUnload = (e) => {
    //     // You can show a confirmation message if needed
    //     //e.returnValue = ''; // This line prevents the default browser confirmation dialog
    //     // Call your logout function
    //     localStorage.clear();
    //     sessionStorage.clear();
    // };


    // Event listeners for user activity
    useEffect(() => {
        window.addEventListener('mousemove', updateActivity);
        window.addEventListener('keydown', updateActivity);
        // window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('mousemove', updateActivity);
            window.removeEventListener('keydown', updateActivity);
            // window.removeEventListener('beforeunload', handleBeforeUnload);

        };
    }, []);

    // Function to check for inactivity and log out if needed
    const checkInactivity = () => {
        if (isSignedIn) {
            const currentTime = new Date().getTime();
            const inactivityPeriod = 10 * 60 * 1000; // 10 minutes in milliseconds
            if (currentTime - lastActivity > inactivityPeriod) {
                // Perform logout action here (e.g., clear user credentials)
                console.log('You have been automatically logged out due to inactivity.');
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = "/login";
            }
        }
    };

    // Check for inactivity periodically (every minute)
    useEffect(() => {
        // console.log(lastActivity)
        const inactivityCheckInterval = 60 * 1000; // 1 minute in milliseconds

        const intervalId = setInterval(checkInactivity, inactivityCheckInterval);

        return () => {
            clearInterval(intervalId);
        };
    }, [lastActivity]);

    return (
        <>
            <ToastContainer />
            <Router>
                <Routes>
                    <Route exact path='/login' element={<Login />}></Route>
                    <Route exact path='/forgetPassword' element={<ForgetPassword />}></Route>
                    <Route exact path='/login-with-otp' element={<LoginOtp />}></Route>
                    <Route exact path='/department-otp-verify' element={<DepartmentOtp />}></Route>
                    <Route exact path='/department-switch' element={<DepartmentSwitch />}></Route>
                    <Route path="" element={<PrivateRoute isSignedIn={isSignedIn} />}>
                        <Route path="" element={<Dashboard />} />
                        <Route exact path='/profileDetail/:id' element={<ProfilePage />}></Route>
                        <Route exact path='/view-user/:id' element={<ViewUserPage />}></Route>
                        <Route path="/users" element={<Users />} />
                        <Route path="/add-user" element={<AddUser />} />
                        <Route path="/edit-user/:id" element={<EditUser />} />
                        <Route path="/profile/:id" element={<EditUser />} />
                        <Route path="/add-scheme" element={<AddNewScheme />} />
                        <Route path="/schemes" element={<Schemes />} />
                        <Route path="/schemes/:id" element={<SchemeDetails />} />
                        <Route path="/view-scheme/:id" element={<ViewScheme />} />
                        <Route path="/edit-scheme/:id" element={<EditScheme />} />
                        <Route path="/budgets" element={<Budgets />} />
                        <Route path="/view-budget/:id" element={<ViewBudgetForm />} />
                        <Route path="/edit-budget/:id" element={<EditBudget />} />
                        <Route path="/add-budget" element={<AddBudget />} />
                        <Route path="/beneficiary" element={<BeneficiariesMgt />} />
                        <Route path="/beneficiary/:id" element={<ViewBeneficiary />} />
                        <Route path="/edit-beneficiary/:id" element={<EditBeneficiary />} />
                        <Route path="/add-beneficiary" element={<AddBeneficiary />} />
                        <Route path="/invoices" element={<InvoiceList />} />
                        <Route path="/add-sanction-order" element={<AddVoucher />} />
                        <Route path="/fund-invoice-list" element={<FundInvoice />} />
                        <Route path="/edit-invoice/:invoice_id" element={<UpdateInvoice />} />
                        <Route path="/view-invoice/:invoice_id" element={<UpdateInvoice />} />
                        <Route path="/fund-approval" element={<Approval />} />
                        
                        <Route path="/fund-payment-status" element={<PaymentStatus />} />
                        <Route path="/fund-proceed-for-payment" element={<ProceedForPayment />} />
                        <Route path="/payment-report" element={<PaymentReport />} />
                        <Route path="/challan" element={<Challan />} />
                        <Route path="/add-challan" element={<AddChallan />} />
                        <Route path="/edit-challan/:type/:id" element={<AddChallan />} />
                        <Route path="/interest" element={<InterestReport />} />
                        <Route path="/beneficiary-report" element={<BeneficiaryReport />} />
                        <Route path="/scheme-subhead" element={<SchemeSubHeadReport />} />
                        <Route path="/tds-it" element={<TDSit />} />
                        <Route path="/add-tds-it" element={<AddTDSITChallan />} />
                        <Route path="/tds-it-report" element={<TDSitReport />} />
                        <Route path="/gst-report" element={<GSTReport />} />
                        <Route path="/other-report" element={<OTHERReport />} />
                        <Route path="/gis" element={<GIS />} />
                        <Route path="/nps" element={<NPS />} />
                        <Route path="/gst" element={<GST />} />
                        <Route path="/other" element={<OTHER />} />
                        <Route path="/add-gis" element={<AddGISChallan />} />
                        <Route path="/add-other" element={<AddOtherChallan />} />
                        <Route path="/add-gst" element={<AddGSTChallan />} />
                        <Route path="/add-nps" element={<AddNPSChallan />} />
                        <Route path="/gis-report" element={<GISReport />} />
                        <Route path="/nps-report" element={<NPSReport />} />
                        <Route path="/depository" element={<DepositoryListPage />} />
                        <Route path="/add-depository" element={<AddDepositoryPage />} />
                        <Route path="/add-depository/:id" element={<AddDepositoryPage />} />
                        <Route path="/add-beneficiary-account/:id" element={<AddBeneficiaryAccountPage />} />
                        <Route path="/accumulated-deduction" element={<AccumulatedDeduction />} />
                        <Route path="/add-budget-reappropriate" element={<AddBudgetReappropriatePage />} />
                        <Route path="/add-budget-reappropriate/:id" element={<AddBudgetReappropriatePage />} />
                        <Route path="/budget-reappropriate" element={<BudgetReappropriateListPage />} />
                        <Route path="/add-beneficiary-reimbursement" element={<AddBeneficiaryReimbursementPage />} />
                        <Route path="/add-beneficiary-reimbursement/:id" element={<ViewBeneficiaryReimbursementPage />} />
                        <Route path="/edit-beneficiary-reimbursement/:id" element={<EditBeneficiaryReimbursementPage />} />
                        <Route path="/beneficiary-reimbursement" element={<BeneficiaryReimbursementListPage />} />
                        <Route path="/beneficiary-reimbursement/:listType" element={<BeneficiaryReimbursementListPage />} />
                        {/* Authority */}
                        <Route path="/add-authority" element={<AddAuthorityPage />} />
                        <Route path="/authoritys" element={<AuthorityListPage />} />
                        {/* Multi Department */}
                        <Route path="/departments" element={<Departments />} />
                        <Route path="/add-department" element={<AddDepartment />} />
                        <Route path="/edit-department/:id" element={<AddDepartment />} />
                        <Route path="/department/activity" element={<ActivityListPage />} />
                        <Route path="/department/add-activity" element={<AddActivityPage />} />
                        <Route path="/department/edit-activity/:id" element={<AddActivityPage />} />
                        <Route path="/department/subactivity" element={<SubActivityListPage />} />
                        <Route path="/department/add-subactivity" element={<AddSubActivityPage />} />
                        <Route path="/department/edit-subactivity/:id" element={<AddSubActivityPage />} />
                        <Route path="/allocate-budget" element={<AllocateBudgetPage />} />
                        <Route path="/view-allocate-budget/:viewId" element={<ViewBudgetPage />} />
                        <Route path="/edit-allocate-budget/:id" element={<EditAllocateBudget />} />
                        {/* <Route path="/budget-payment/:id" element={<BudgetPaymentPage />} /> */}
                        <Route path="/allocate-budget-approval" element={<AllocateApprovalListPage />} />
                        <Route path="/allocate-budgets" element={<AllocateListPage />} />
                        <Route path="/allocate-budgets/:listType" element={<AllocateListPage />} />
                        <Route path="/payment-budgets" element={<BudgetPaymentListPage />} />
                        <Route path="/addchildAccount" element={<AddAccountPage />} />
                        <Route path="/editchildAccount/:id" element={<AddAccountPage />} />
                        <Route path="/viewchildAccount/:viewId" element={<AddAccountPage />} />
                        <Route path="/childAccountList" element={<ChildAccountListPage />} />
                        <Route path="/addchildSchemeAccount" element={<AddScheneAccountPage />} />
                        <Route path="/editchildSchemeAccount/:id" element={<AddScheneAccountPage />} />
                        <Route path="/viewchildSchemeAccount/:viewId" element={<AddScheneAccountPage />} />
                        <Route path="/schemeAccountList" element={<SchemeAccountListPage />} />
                        <Route path="/ppa" element={<Ppa />} />
                        <Route path="/beneficiary-ppa" element={<BeneficiaryPpa />} />
                        <Route path="/mpr-report" element={<MprReportPage />} />
                        <Route path="/expense-mpr-report" element={<ExpenseMprReportPage />} />
                        <Route path="/royalty" element={<Royalty />} />
                        <Route path="/royalty_report" element={<RoyaltyReport />} />
                        <Route path="/labour_report" element={<LabourReport />} />
                        <Route path="/royalty_labour_approval" element={<RoyaltyApproval />} />
                        <Route path="/labour" element={<LabourCess />} />
                        <Route path="/add-royalty" element={<AddRoyalty />} />
                        <Route path="/view-royalty/:id" element={<ViewRoyalty />} />
                        <Route path="/view-labourCess/:id" element={<ViewLabourCess />} />
                        <Route path="/add-labour" element={<AddLabourCess />} />
                        <Route path="/view-invoice-new/:id" element={<ViewInvoice />} />
                        <Route path="/add-department-user" element={<AddDepartmentUser />} />
                        <Route path="/xml-reportings" element={<XmlReport />} />
                        <Route path="/add-budget-surrender" element={<AddBudgetSurrenderPage />} />
                        <Route path="/budget-surrender" element={<BudgetSurrenderList />} />
                        <Route path="/budget-surrender-pending" element={<BudgetSurrenderPendingList />} />
                    </Route>
                    <Route path='*' element={<h1>404 page not found</h1>}></Route>
                </Routes>
            </Router>
        </>
    );
}

export default App;
