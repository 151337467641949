import {useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import {useDispatch, useSelector} from 'react-redux';
import {get_financial_year, get_schemes_by_fy_added_pending, getSchemeSubheadReports, getXmlReports} from "../../helper/Api";



export function XmlReportList() {
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [schemeList, setSchemeList] = useState([])
    const dispatch = useDispatch();

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const fetch_list = async () => {
        const schemeResponse = await getXmlReports();
        if (schemeResponse.data.status) {
            setSchemeList(schemeResponse.data.result);
        }
    }
    useEffect(() => {
        fetch_list()
    }, [dispatch])

    //  const paginatedData = schemeSubHeadList?.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };
    const columns = [
        {
            name: 'Scheme Name',
            selector: (row) => row.name,
            sortable: true,
            width: "200px",
            wrap: true,
            right: true
        },
        {
            name: 'Scheme Code',
            selector: (row) => row.code,
            sortable: true,
            width: "200px",
            wrap: true,
        },
        {
            name: 'File Name',
            selector: (row) => row.filename,
            sortable: true,
            width: "200px",
            wrap: true,
        },
        {
            name: 'Ack File Name',
            selector: (row) => row.ack_filename,
            sortable: true,
            width: "200px",
            wrap: true,
        },
        {
            name: 'Report Date',
            selector: (row) => row.uploaded_at,
            sortable: true,
            width: "200px",
            wrap: true,
        },
        {
            name: 'Ack Date',
            selector: (row) => row.pulled_at,
            sortable: true,
            width: "200px",
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row) => row.response_status,
            sortable: true,
            width: "200px",
            wrap: true,
        },
        {
            name: 'Errors',
            selector: (row) => '',
            sortable: true,
            width: "200px",
            wrap: true,
        },
    ];

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    return (
        <>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={schemeList}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={schemeList.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                /*paginationComponent={() => (
                    <CustomPagination
                        rowsPerPage={rowsPerPage}
                        rowCount={schemeSubHeadList?.length}
                        currentPage={currentPage}
                        onChangePage={handleChangePage}
                    />
                )}*/
            />
        </>

    );
}
