import React, { useEffect, useRef, useState } from 'react'
import Tools from '../../components/Tools/Tools';
import ManageDisbursment from '../../components/Disbursment/ManageDisbursment';
import ManageDisbursmentHeader from '../../components/Disbursment/ManageDisbursmentHeader';
import { Tab, Tabs } from 'react-bootstrap';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import VenderContractServiceFilter from '../Filter/vendorContractServiceFilter';
import { get_disbursment_invoices,get_financial_year,get_consolidate_disbursment_invoices} from '../../helper/Api';
import {
    GET_D_INVOICES_START,
    GET_D_INVOICES_SUCCESS,
    GET_D_INVOICES_FAIL
} from '../../RTK/Slices/DisbursmentInvoiceSlice';
import { useDispatch, useSelector } from 'react-redux';
const InvoiceList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const dateColumnRef = useRef('voucher_date');
    const searchStrRef = useRef('');
    const tierRef = useRef('');
    const departmentIdRef = useRef('');
    const departmentId1Ref = useRef('');
    const fyIdRef = useRef(2);
    const selectDateRef = useRef('');
    const departmentRef = useRef([]);
    const searchParams = new URLSearchParams(location.search);
    const list = searchParams.get('list');
    const [heading, setHeading] = useState('Invoice-Service');
    const [filter, setFilter] = useState('own');
    const [data, setData] = useState([]);
    const [fyList, setFyList] = useState([]);
    const handleDateChange = (dateColumn,searchStr,fyId, tier, departmentId, departmentId1) => {
        dateColumnRef.current=dateColumn;
        searchStrRef.current = searchStr;
        tierRef.current = tier;
        fyIdRef.current = fyId;
        departmentRef.current = departmentId;
        departmentId1Ref.current = departmentId1;
        //voucherDateRef.current = voucherDate;
        // selectDateRef.current = selectDate;
        if (filter == 'child') {
            fetchInvoices({
                dateColumn:dateColumnRef.current,
                search_str: searchStrRef.current,
                fy_id: fyIdRef.current,
                department_id:departmentRef.current,
            });
        }
        else if (filter == 'consolidate') {
            fetchInvoiceConsolidate();
        }
    };
    const handleTabSelect = (key) => {
        if (key == 'paymentStatus') {
            setHeading("Invoice Payment");
            navigate('/fund-invoice-list?pending=true');
        } else if (key == 'InvoiceList') {
            setHeading("Invoice Approve");
            navigate('/fund-invoice-list?pending=false');
        } else if (key == 'Invoice-Service') {
            navigate('/invoices?list=true')
        }
    };
    const fetchInvoices = async (payload) => {
        dispatch(GET_D_INVOICES_START());
        try {
            const invoices_response = await get_disbursment_invoices(payload);
            if (invoices_response.data.status) {
                dispatch(GET_D_INVOICES_SUCCESS(invoices_response.data.list))
                setData(invoices_response.data.list)
            } else {
                dispatch(GET_D_INVOICES_FAIL(invoices_response.data.message))
            }
        } catch (error) {
            dispatch(GET_D_INVOICES_FAIL('something went wrong'))
        }
    }
    const fetchInvoiceConsolidate = async () => {
        try {
            let departmentId = '';
            console.log("conidtion", departmentId1Ref.current, departmentId1Ref.current);
            if (departmentIdRef.current !== "" && departmentId1Ref.current !== "") {
                // You can decide which value to send, for example, let's choose departmentIdRef.current
                departmentId = departmentId1Ref.current;
                console.log("if conidtion", departmentId1Ref.current);
            }
            else {
                console.log("else conidtion", departmentIdRef.current);
                departmentId = departmentIdRef.current;
            }
            const invoiceData = await get_consolidate_disbursment_invoices({
                department_id: departmentId || 1,
                fy_id: fyIdRef.current || '',
                search_str: searchStrRef.current || '',
                dateColumn:dateColumnRef.current||""
            });
            if (invoiceData.data.status) {
                dispatch(GET_D_INVOICES_SUCCESS(invoiceData.data.list))
                setData(invoiceData.data.list)
            } else {
                dispatch(GET_D_INVOICES_FAIL('something went wrong'))
            }
        } catch (error) {
            dispatch(GET_D_INVOICES_FAIL('something went wrong'));
        }

    }
    // Fetch financial years
    const fetchFinancialYears = async () => {
        const fy = await get_financial_year();
        if (fy.data.status) {
            setFyList(fy.data.list);
        }
    };
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const newReportType = queryParams.get('reportType') || 'own';  // default fallback
        setFilter(newReportType);
        fetchFinancialYears();
        if (newReportType == 'own') {
            fetchInvoices({search_str: searchStrRef.current,department_id:departmentRef.current,fy_id: fyIdRef.current,dateColumn:dateColumnRef.current});
        }
        else{
            setData([]);
        }
    }, [location.search]);

    return (
        <div>
            <Tools />
            {/* heading={heading} */}
            <ManageDisbursmentHeader onDateChange={handleDateChange} type="Disbursments" />
            <VenderContractServiceFilter filterType={filter} onDateChange={handleDateChange} fyData={fyList}/>
            <div className='scheme p-2'>
                {
                    list && <div className='all_tabs'>
                        <Tabs
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            activeKey={'Invoice-Service'}
                            onSelect={handleTabSelect}
                        >
                            <Tab eventKey="InvoiceList" title="Invoice Approval" className='p-2'></Tab>
                            <Tab eventKey="paymentStatus" title="Invoice Payment" className='p-2'></Tab>
                            <Tab eventKey="Invoice-Service" title="Vendor/Contract/Invoice/Service Provider Payments" className='p-2'></Tab>
                            <Tab eventKey="Penalty" title="Penalties Approval" className='p-2'></Tab>
                            <Tab eventKey="List-Penalty" title="Penalty Payments" className='p-2'></Tab>
                            <Tab eventKey="Withheld" title="Withheld Amount Payment" className='p-2'></Tab>
                            <Tab eventKey="List-Withheld" title="Withheld Amount Payments" className='p-2'></Tab>
                        </Tabs>
                    </div>
                }

                <ManageDisbursment tableData={data} />
            </div>
        </div>
    )
}
export default InvoiceList
