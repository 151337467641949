import React, {useState, useEffect, useRef} from 'react'
import Tools from '../../components/Tools/Tools';
import ManageDisbursmentHeader from '../../components/Disbursment/ManageDisbursmentHeader'
import BeneficiaryPpaList from '../../components/Ppa/BeneficiaryPpaList';
import { getBeneficiaryPPAList, getConsolidateBeneficiaryPPAList, getExportBeneficiaryPPAList, getExportConsolidateBeneficiaryPPAList } from '../../helper/Api';
import { useLocation } from 'react-router-dom';
import PpaFilter from '../Filter/PpaFilter';
import { useSelector } from 'react-redux';
import LootieLoaderDot from '../../components/LootieLoader/LootieLoaderDot';



export default function BeneficiaryPpa() {
    const {authData} = useSelector((state) => state.authData);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchStr, setToSearchStr] = useState('');
    const [data, setData] = useState([]);
    const [dataLength, setDataLength] = useState(0);
    const location = useLocation();
    const [departmentId, setDepartmentId] = useState('');
    const [filter, setFilter] = useState('own');
    const [tier, setTier] = useState('');
    const [exportExcel, setExport] = useState('');
    const [loading, setLoader] = useState(false);
    const departmentRef = useRef({
        departmentId1: '',
        departmentId: '',
    });
    const pageRef = useRef({
        start: 0,
        end: 0,
        currentPage: 1,
    });

    const handleDateChange = (start, end, searchStr, fyId, tier, department_id, departmentId1, exportToExcel) => {
        setFromDate(start);
        setToDate(end);
        setToSearchStr(searchStr);
        setDepartmentId(department_id);
        setTier(tier);
        departmentRef.current.departmentId1 = departmentId1;
        setExport(exportToExcel);
    };

    const columns = [
        { Header: 'S.No', accessor: 'sno', style: { width: '50px' } },
        { Header: 'Tier', accessor: 'department_level', style: { width: '100px' } },
        { Header: 'Department Code', accessor: 'name', style: { width: '150px' } },
        { Header: 'PPA No', accessor: 'ppa_no', style: { width: '50px' } },
        { Header: 'PPA Approval DateTime', accessor: 'l3_approval_datetime', style: { width: '150px' } },
        { Header: 'Total Credit Accounts', accessor: 'invoice_count', style: { width: '50px' } },
        { Header: 'No of Accounts S.No', accessor: 'no_of_accounts', style: { width: '50px' } },
        { Header: 'Beneficiary Name', accessor: 'beneficiary_name', style: { width: '150px' } },
        { Header: 'Account No.', accessor: 'account_no', style: { width: '150px' } },
        { Header: 'IFSC Code', accessor: 'ifsc_code', style: { width: '100px' } },
        { Header: 'Sanctioned Amount', accessor: 'sanctioned_amount', style: { width: '100px' } },
        { Header: 'Deduction Amount', accessor: 'deduction_amount', style: { width: '100px' } },
        { Header: 'Payment Amount', accessor: 'payment_amount', style: { width: '100px' } },
        { Header: 'Download PPA', accessor: 'ppa_url', style: { width: '100px' } },

    ];

    const downloadFile = (url) => {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx"); // Suggested filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    const fetchExcelRecords = async (department_id=null) => {
        try {
            setLoader(true);
            const resp = await getExportBeneficiaryPPAList({
                from_date: fromDate, 
                to_date: toDate, search_str: searchStr, department_id
            });
            if (resp.data.status) {
                setLoader(false);
                downloadFile(resp.data.url);
            }
        } catch (error) {
            setLoader(false);
            setData([]);
        }
    }

    const fetchExcelConsolidateRecords = async (department_id) => {
        try {
            setLoader(true);
            const resp = await getExportConsolidateBeneficiaryPPAList({
                from_date: fromDate, 
                to_date: toDate, search_str: searchStr, department_id
            });
            if (resp.data.status) {
                setLoader(false);
                downloadFile(resp.data.url);
            }
        } catch (error) {
            setLoader(false);
            setData([]);
        }
    }

    const handlePageChange = async (newPage, limit, filterType) => {
        if(!filterType) {
            filterType = filter;
        }
        console.log('filterType', filterType);

        if(exportExcel) {
            if (filterType == 'own') {
                await fetchExcelRecords();
            }else if (filterType == 'child') {
                if(departmentId) {
                    await fetchExcelRecords(departmentId);
                } else {
                    setData([]);
                }
            } else if (filterType == 'consolidate') {
                if(departmentId || departmentRef.current.departmentId1) {
                    await fetchExcelConsolidateRecords(departmentRef.current.departmentId1 || departmentId);
                }else {
                    setData([]);
                }
            }
        }else {
            if (filterType == 'own') {
                await fetchRecords(limit, newPage);
            }else if (filterType == 'child') {
                if(departmentId) {
                    await fetchRecords(limit, newPage, departmentId);
                } else {
                    setData([]);
                }
            } else if (filterType == 'consolidate') {
                if(departmentId || departmentRef.current.departmentId1) {
                    await fetchConsolidateRecords(limit, newPage, departmentRef.current.departmentId1 || departmentId);
                }else {
                    setData([]);
                }
            }
        }
    };

    const fetchRecords = async (limit, offset, department_id=null) => {
            try {
                const resp = await getBeneficiaryPPAList({
                    limit, offset, 
                    from_date: fromDate, 
                    to_date: toDate, search_str: searchStr, department_id
                });
                if (resp.data.status) {
                    setData(resp.data.result.data);
                    setDataLength(resp.data.result.total_count);
                    pageRef.current.currentPage = offset;
                    pageRef.current.start = (offset - 1) * limit;
                    if(resp.data.result.data.length) {
                        pageRef.current.start += 1;
                    }
                    pageRef.current.end = (offset * limit) > resp.data.result.total_count ? resp.data.result.total_count : (offset * limit);
                }
            } catch (error) {
                setData([]);
            }
        }

        const fetchConsolidateRecords = async (limit, offset, department_id) => {
            try {
                if (!department_id) {
                    // You can decide which value to send, for example, let's choose departmentIdRef.current
                    department_id = authData.user.department_id;
                }
                const resp = await getConsolidateBeneficiaryPPAList({
                    limit, offset, 
                    from_date: fromDate, 
                    to_date: toDate, search_str: searchStr, department_id
                });
                if (resp.data.status) {
                    setData(resp.data.result.data);
                    setDataLength(resp.data.result.total_count);
                    pageRef.current.currentPage = offset;
                    pageRef.current.start = (offset - 1) * limit;
                    if(resp.data.result.data.length) {
                        pageRef.current.start += 1;
                    }
                    pageRef.current.end = (offset * limit) > resp.data.result.total_count ? resp.data.result.total_count : (offset * limit);
                }
            } catch (error) {
                setData([]);
            }
        }
    
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const newReportType = queryParams.get('reportType') || 'own';  // default fallback
        console.log('newReportType', newReportType);
        setFilter(newReportType);
        handlePageChange(1, 50, newReportType);
        }, [fromDate, toDate, searchStr, departmentId, location.search, tier, exportExcel]);
    

    return (
        <div>
            {loading && <div >
                <LootieLoaderDot/>
            </div>}
            <Tools />
            <ManageDisbursmentHeader type="Beneficiary PPA Report" prefix={filter}  />
            <PpaFilter onDateChange={handleDateChange} filterType={filter}  />
            <div className='scheme p-2'>
                <BeneficiaryPpaList data={data} columns={columns} initialItemsPerPageOptions={[50, 100, 200, 500]} onPageChange={handlePageChange} recordsCount={dataLength} startItem={pageRef.current.start} endItem={pageRef.current.end} currentPage={pageRef.current.currentPage}/>
            </div>
        </div>
    )
}