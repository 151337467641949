import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { get_departments } from "../../helper/Api";
import { useSelector } from 'react-redux';

const SchemeFilter = ({ filterType, onDateChange, fyData }) => {
    const { authData } = useSelector((state) => state.authData);
    const [selected, setSelected] = useState(0);
    // Initialize refs to store form values instead of state
    const searchStrRef = useRef('');
    const fyIdRef = useRef(2);
    const tierRef = useRef('');
    const departmentIdRef = useRef('');
    const departmentId1Ref = useRef('');
    const departmentRef = useRef([]);
    const departmentRef1 = useRef([]);
    // Fetch departments
    const fetchDepartments = async (level) => {
        try {
            const departments = await get_departments({ department_level: level, parent_department_id: authData.user.department_id });
            if (departments.data.status) {
                departmentRef.current = departments.data.result;
            }
        } catch (error) {
            console.log("get_departments error");
        }
    };
    const fetchDepartmentsById = async (value, parent_department_id = null) => {
        const departments = await get_departments({ department_level: value, parent_department_id });
        if (departments.data.status) {
            if (value === 'T2') {
                departmentRef.current = departments.data.result;
            }
            if (value === 'T3') {
                departmentRef1.current = departments.data.result;
            }
        }
    }

    const handleInput = async (e) => {
        const name = e.target.name;
        const value = e.target.value;
        // Update refs based on user input
        if (name === 'search_str') {
            searchStrRef.current = value;
        } else if (name === 'fy_id') {
            fyIdRef.current = value;
        } else if (name === 'tier') {
            tierRef.current = value;
            departmentId1Ref.current = "";
            departmentIdRef.current = "";
            departmentRef.current = [];
            departmentRef1.current = [];
            if(filterType === 'child' || (filterType === 'consolidate' && (value !== 'T1' || value !== 'T2'))) {
                selectIndexFind(value);
            }
        } else if (name === 'department_id') {
            departmentIdRef.current = value;
            if (selected == 3) {
                fetchDepartmentsById("T3", departmentIdRef.current);
            }
        } else if (name === 'department_id1') {
            departmentId1Ref.current = value;
        }
    };
    const handleFilterClick = () => {
        onDateChange(
            searchStrRef.current,
            fyIdRef.current,
            tierRef.current,
            departmentIdRef.current,
            departmentId1Ref.current,
        );
    };

    const clearFilter = () => {
        searchStrRef.current = '';
        tierRef.current = '';
        departmentIdRef.current = '';
        departmentId1Ref.current = '';
        fyIdRef.current = 2;
        onDateChange('', '', 2, '', '');
    };

    const selectIndexFind = async (start) => {
        const items = ["T1", "T2", "T3", "T4"]; // Example array
        const index = items.indexOf(start) + 1;
        const selectedIndex = items.indexOf(authData.user.department_details.department_level) + 1;
        const difference = index - selectedIndex;
        setSelected(difference);
        if ((tierRef.current == 'T3' && authData.user.department_details.department_level == 'T1' && filterType === 'consolidate') || (tierRef.current == 'T2' && filterType === 'child')) {
            fetchDepartments("T2");
            departmentId1Ref.current = "";
        }else if (tierRef.current == 'T4' && difference == 3 && filterType === 'consolidate') {
            fetchDepartments("T2");
            departmentId1Ref.current = "";
        }else if (tierRef.current == 'T3' && filterType === 'child') {
            fetchDepartments("T3");
        }
        else if (tierRef.current == 'T4' && filterType === 'consolidate') {
            fetchDepartments("T3");
        } else if (tierRef.current == 'T4' && filterType === 'child') {
            fetchDepartments("T4");
        }
        return difference;
    };

    useEffect(() => {
        tierRef.current = "";
    }, [filterType]);
    
    return (
        <div className="add_new_user mt-2">
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <h5>Filters</h5>
                    </Col>
                    <Col sm={4}>
                        <Form.Group controlId="">
                            <select
                                name="fy_id"
                                className="form-control mb-2"
                                onChange={handleInput}
                            >
                                <option value="">---select year---</option>
                                {fyData.map((fy) => (
                                    <option
                                        value={fy.id}
                                        key={fy.id}
                                        selected={Number(fy.id) === Number(fyIdRef.current)}
                                    >
                                        {fy.year}
                                    </option>
                                ))}
                            </select>
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                        <Form.Group controlId="">
                            <Form.Control
                                type="text"
                                name="search_str"
                                onChange={handleInput}
                                value={searchStrRef.current}
                                placeholder="Enter to search ..."
                            />
                        </Form.Group>
                    </Col>
                    {(filterType === 'child' || filterType === 'consolidate') && (
                        <>
                            <Col sm={4}>
                                <Form.Group controlId="">
                                    <select
                                        className="form-control"
                                        name="tier"
                                        onChange={handleInput}
                                    >
                                        <option value="">Select Department Tier</option>
                                        {(authData.user.department_details.department_level === 'T1' ||
                                            authData.user.department_details.department_level === 'T2') && (
                                                <option value="T2" selected={tierRef.current === 'T2'}>
                                                    T2
                                                </option>
                                            )}
                                        {(authData.user.department_details.department_level === 'T1' ||
                                            authData.user.department_details.department_level === 'T2' ||
                                            authData.user.department_details.department_level === 'T3') && (
                                                <option value="T3" selected={tierRef.current === 'T3'}>
                                                    T3
                                                </option>
                                            )}
                                        {(authData.user.department_details.department_level === 'T1' ||
                                            authData.user.department_details.department_level === 'T2' ||
                                            authData.user.department_details.department_level === 'T3' ||
                                            authData.user.department_details.department_level === 'T4') && (
                                                <option value="T4" selected={tierRef.current === 'T4'}>
                                                    T4
                                                </option>
                                            )}
                                    </select>
                                </Form.Group>
                            </Col>
                            {filterType == "child" || selected > 1 ?
                            <Col sm={4}>
                                <Form.Group controlId="">
                                    <select
                                        className="form-control"
                                        name="department_id"
                                        onChange={handleInput}
                                    >
                                        <option value="">Select Department</option>
                                        {departmentRef.current.map((option) => (
                                            <option
                                                key={option.id}
                                                value={option.id}
                                                selected={departmentIdRef.current === option.id}
                                            >
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Group>
                            </Col> : "" }
                            {filterType == "consolidate" && selected == 3 && 
                            <Col sm={4}>
                                <Form.Group controlId="">
                                    <select
                                        className="form-control"
                                        name="department_id1"
                                        onChange={handleInput}
                                    >
                                        <option value="">Select Department</option>
                                        {departmentRef1.current.map((option) => (
                                            <option
                                                key={option.id}
                                                value={option.id}
                                                selected={departmentRef1.current === option.id}
                                            >
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Group>
                            </Col>}
                        </>
                    )}
                    <Col sm={12}>
                        <div className="d-flex mt-3">
                            <div className="text-start mx-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={clearFilter}
                                >
                                    Clear
                                </button>
                            </div>
                            <div className="text-start">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleFilterClick}
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SchemeFilter;
