import React, {useState} from 'react'
import Tools from "../../components/Tools/Tools";
import ManageDisbursmentHeader from "../../components/Disbursment/ManageDisbursmentHeader";
import { XmlReportList } from '../../components/Reports/XmlReportList';


const XmlReport = () => {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');


    const handleDateChange = (start, end) => {
        setFromDate(start);
        setToDate(end);

    };
    return (
        <div>
            <Tools/>
            <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} type={'Xml Reportings'}/>
            <div className='scheme p-3'>
                <XmlReportList />
            </div>
        </div>
    )
}

export default XmlReport
