import React, { useState, useEffect, useRef } from 'react'
import InterestList from "../../components/Reports/InterestList";
import Tools from "../../components/Tools/Tools";
import { Col, Container, Form, Row } from "react-bootstrap";
import ManageDisbursmentHeader from "../../components/Disbursment/ManageDisbursmentHeader";
import InterestFilter from '../Filter/interestFilter';
import { get_financial_year, getInterestReports,get_consolidate_disbursment_invoices} from '../../helper/Api';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    GET_D_INVOICES_START,
    GET_D_INVOICES_SUCCESS,
    GET_D_INVOICES_FAIL
} from '../../RTK/Slices/DisbursmentInvoiceSlice';
const InterestReport = () => {
    const { authData } = useSelector((state) => state.authData);
    const dispatch = useDispatch();
    // const [fromDate, setFromDate] = useState('');
    // const [toDate, setToDate] = useState('');
    const location = useLocation();
    const [filter, setFilter] = useState('own');
    const fromDate = useRef('');
    const toDate = useRef('');
    const searchStrRef = useRef('');
    const fyIdRef = useRef(2);
    const departmentIdRef = useRef('');
    const departmentId1Ref = useRef('');
    const tierRef = useRef('');
    const [fyList, setFyList] = useState([]);
    const [data, setData] = useState([]);
    const handleDateChange = (searchStr, fyId, tier, departmentId, departmentId1) => {
        searchStrRef.current = searchStr;
        fyIdRef.current = fyId;
        tierRef.current = tier;
        departmentIdRef.current = departmentId;
        departmentId1Ref.current = departmentId1;
        if (filter == 'child') {
            fetchReports();
        }
        else if (filter == 'consolidate') {
            fetchReportsConsolidate();
        }
    };
    const fetchFinancialYears = async () => {
        const fy = await get_financial_year();
        if (fy.data.status) {
            setFyList(fy.data.list);
        }
    };
    const fetchReports = async () => {
        try {
            const data = await getInterestReports({
                search_str: searchStrRef.current || '',
                fy_id: fyIdRef.current || '',
                department_id: departmentIdRef.current || '',
            });
            if (data.data.status) {
                setData(data.data.result);
            }
        } catch (error) {
            setData([]);
        }
    }
    const fetchReportsConsolidate = async () => {
        try {
            let departmentId = '';
            if (departmentIdRef.current !== "" && departmentId1Ref.current !== "") {
                departmentId = departmentId1Ref.current;
            }
            else {
                departmentId = departmentIdRef.current;
            }
            const invoiceData = await getInterestReports({
                parent_department_id: departmentId || 1,
                fy_id: fyIdRef.current || '',
                search_str: searchStrRef.current || '',
            });
            if (invoiceData.data.status) {
                dispatch(GET_D_INVOICES_SUCCESS(invoiceData.data.list))
                setData(invoiceData.data.list)
            } else {
                dispatch(GET_D_INVOICES_FAIL('something went wrong'))
            }
        } catch (error) {
            dispatch(GET_D_INVOICES_FAIL('something went wrong'));
        }

    }
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const newReportType = queryParams.get('reportType') || 'own';  // default fallback
        setFilter(newReportType);
        fetchFinancialYears();
        if (newReportType == 'own') {
            fetchReports();
        }
        else {
            setData([]);
        }
    }, [location.search]);
    return (
        <div>
            <Tools />
            <ManageDisbursmentHeader onDateChange={handleDateChange} type="Interest" />
            <InterestFilter onDateChange={handleDateChange} filterType={filter} fyData={fyList} />
            <div className='scheme p-3'>
                <InterestList data={data} />
            </div>
        </div>
    )
}

export default InterestReport
