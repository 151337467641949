import React, {useState} from 'react'
import Tools from '../../components/Tools/Tools'
import {useSelector} from 'react-redux';
import ManageDisbursmentHeader from '../../components/Disbursment/ManageDisbursmentHeader';
import BudgetSurrender from '../../components/BudgetSurrender/BudgetSurrender';


export default function BudgetSurrenderPendingList() {
    const {authData} = useSelector((state) => state.authData);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateColumn, setDateColumn] = useState('approval_date');
    const [searchStr, setToSearchStr] = useState('');


    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setFromDate(start);
        setToDate(end);
        setDateColumn(dateColumn);
        setToSearchStr(searchStr);
    };
    return (
        <div>
            <Tools/>
            <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} dateColumnType={dateColumn} type="Budget Surrender Approvals" />
            <div className='scheme p-2'>
                <BudgetSurrender fromDate={fromDate} toDate={toDate} dateColumn={dateColumn} searchStr={searchStr} pending={true}/>
            </div>
        </div>
    )
}