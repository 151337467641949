import React, { useState, useEffect, useRef } from 'react';
import './BeneficiaryPpaList.css'; // Create this file for styling
import { AiOutlineCaretUp,  AiOutlineCaretDown, AiOutlineVerticalAlignBottom } from 'react-icons/ai';


const BeneficiaryPpaList = ({ data, columns, initialItemsPerPageOptions, onPageChange, recordsCount, startItem, endItem, currentPage }) => {
    const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPageOptions[0]);
    const [sortedData, setSortedData] = useState([...data]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const [hoveredColumn, setHoveredColumn] = useState(null); // Track which column is hovered


    useEffect(() => {
        setSortedData([...data]);
    }, [data]);

    // Sorting handler
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sorted = [...sortedData].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });
        setSortConfig({ key, direction });
        setSortedData(sorted);
    };


    const handlePageClick = (page) => {
        console.log('current page: ', currentPage);
        console.log('pageClick', page);
        if (onPageChange) {
            onPageChange(page, itemsPerPage); // Call the function passed from the parent
        }
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        if (onPageChange) {
            onPageChange(1, e.target.value); // Call the function passed from the parent
        }
    };

    const downloadPPAFun = async (url, ppa_id) => {
            const link = document.createElement('a');
            link.href = url;
            link.download = `ppa_${ppa_id}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    

    return (
        <div>
            <div className="table-parent-div">
                <table className="table">
                    <thead>
                        <tr>
                            {columns.map((column) => (
                                <th
                                    key={column.accessor}
                                    onClick={() => handleSort(column.accessor)}
                                    onMouseEnter={() => setHoveredColumn(column.accessor)} // Set hovered column on enter
                                    onMouseLeave={() => setHoveredColumn(null)} // Clear hovered column on leave
                                    className="sortable-header"
                                    style={{ width: column.width }}>
                                    {column.Header}
                                    {/* {hoveredColumn === column.accessor && ( */}
                                        {/* <span className={`sort-arrow ${sortConfig.direction === 'ascending' ? 'ascending' : 'descending'}`}>
                                            {sortConfig.direction === 'ascending' ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
                                        </span> */}
                                    {/* )} */}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, rowIndex) => (
                            <tr key={'s_no_'+rowIndex} >
                                <td key={rowIndex + startItem} className="ellipsis">
                                    <div className="ellipsis">
                                        {rowIndex + startItem}
                                    </div>
                                </td>
                                <td key={'tier_'+rowIndex} className="ellipsis">
                                    <div className="ellipsis">
                                        {row.department_level}
                                    </div>
                                </td>
                                <td key={'department_code_'+rowIndex} className="ellipsis">
                                    <div className="ellipsis">
                                        {row.name}
                                    </div>
                                </td>
                                <td key={'ppa_no'+rowIndex} className="ellipsis">
                                    <div className="ellipsis">
                                        {row.ppa_no}
                                    </div>
                                </td>
                                <td key={'approval_date_'+rowIndex} className="ellipsis">
                                    <div className="ellipsis">
                                        {row.l3_approval_datetime}
                                    </div>
                                </td>
                                <td key={'invoice_count_'+rowIndex} className="ellipsis">
                                    <div className="ellipsis">
                                        {row.invoice_count}
                                    </div>
                                </td>
                                <td key={'account_code_'+rowIndex} className="ellipsis">
                                    <div className="ellipsis">
                                        
                                    </div>
                                </td>
                                <td key={'beneficiary_name_'+rowIndex} className="ellipsis">
                                    <div className="ellipsis">
                                        {row.invoice_beneficiary_name || row.reimbursement_beneficiary_name}
                                    </div>
                                </td>
                                <td key={'beneficiary_account_no_'+rowIndex} className="ellipsis">
                                    <div className="ellipsis">
                                        {row.invoice_beneficiary_account_no || row.reimbursement_beneficiary_account_no}
                                    </div>
                                </td>
                                <td key={'beneficiary_ifsc_code_'+rowIndex} className="ellipsis">
                                    <div className="ellipsis">
                                        {row.invoice_beneficiary_ifsc_code || row.reimbursement_beneficiary_ifsc_code}
                                    </div>
                                </td>
                                <td key={'sanctioned_amount_'+rowIndex} className="ellipsis">
                                    <div className="ellipsis">
                                        {row.invoice_sanctioned_amount || row.reimbursement_gross_amount}
                                    </div>
                                </td>
                                <td key={'total_deduction_'+rowIndex} className="ellipsis">
                                    <div className="ellipsis">
                                        {row.invoice_total_deduction || row.reimbursement_deduction_amount}
                                    </div>
                                </td>
                                <td key={'total_payable_'+rowIndex} className="ellipsis">
                                    <div className="ellipsis">
                                        {row.invoice_total_payable || row.reimbursement_net_amount}
                                    </div>
                                </td>
                                
                                <td key={'url_'+rowIndex} className="ellipsis">
                                    <div className="ellipsis">
                                        <button type="button" className='btn btn-primary' onClick={ () => downloadPPAFun(row.ppa_url, row.id) }><AiOutlineVerticalAlignBottom /></button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        {data.length === 0 && (
                            <tr>
                                <td colSpan={columns.length} className="no-data">
                                    No data available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="pagination-container">
                {/* Items per page dropdown */}
                <div className="items-per-page">
                    <label>Rows Per Page: </label>
                    <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                        {initialItemsPerPageOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Pagination information */}
                <div className="pagination-info">
                    {startItem}–{endItem} of {recordsCount}
                    <button
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 1 || currentPage < 1}
                        className="pagination__link"
                    >
                        &#8592; {/* Left arrow for Previous */}
                    </button>
                    <button
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={endItem === recordsCount}
                        className="pagination__link"
                    >
                        &#8594; {/* Right arrow for Next */}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BeneficiaryPpaList;