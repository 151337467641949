import React from 'react';
import Tools from "../../components/Tools/Tools";
import AddBudgetSurrenderComponent from '../../components/BudgetSurrender/AddBudgetSurrender';


export default function AddBudgetSurrenderPage() {
    return (
        <div>
          <Tools/>
          {/* <AddBudgetReappropriate /> */}
          {<AddBudgetSurrenderComponent />}

        </div>
      )
}